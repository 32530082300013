.qc-approved {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto;
  grid-template-areas: 
    'header'
    'main';

  &-content {
    background: #FFFFFF;
    padding: 8px 16px 0px 16px;
    grid-area: main;
    overflow: auto;

    table.table {
      background-color: #f8f9fa;
      color: #0d264f;
      font-weight: 400;

      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  .divider {
    height: 1px;
    background-color: #ececf0;
    margin: 16px;
  }

  .highlight-text {
    color: #d25b4e;
    margin-left: 5px;
  }
}
