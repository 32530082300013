.is-align-items-center {
    align-items: center !important;
}

.is-flex-wrap-wrap {
    flex-wrap: wrap!important;
}

.inspection-data {
    padding: 16px 24px;
    border-bottom: 1px solid #E5E9F2;
    &:last-child {
        border-bottom: 0px;
    }
    h3 {
        color: #0081C3;
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0.28px;
        line-height: 19px;
        margin-right: 32px;
        max-width: 164px;
        width: 100%;
    }
    .default-heading {
        color: #91989E;
    }
    .default-state {
        display:flex; 
    }
    div {
        width: 100%;
    }
    .inspection-btn {
        border: 1px solid #E5E9F2;
        border-radius: 21.5px;
        background-color: #FFFFFF;
        font-size: 11px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        max-width: 94px;
        width: 100%;
        padding: 7.5px 0px;
        margin-right: 8px;
    }
    .state-dot {
        height: 12px;
        max-width: 12px;
        width: 100%;
        border-radius: 50%;
        margin-right: 24px;
    }

    .inspection-data-inner {
        max-width: 86px;
        width: 100%;
        margin-right: 24px;
        h4 {
            font-size: 11px;
            font-weight: 900;
            letter-spacing: 0.22px;
            line-height: 15px;
            color: #122048;
            margin-bottom: 2px;
        }
        p {
            font-size: 11px;
            font-weight: 300;
            letter-spacing: 0.22px;
            line-height: 15px;
            color: #122048;
        }
    }
    .inspection-data-detail {
        max-width: 320px;
    }
    .inspection-thumbnail {
        max-width: 96px;
        width: 100%;
        .inspection-thumbnail-inner {
            height: 40px;
            width: 32px;
            border: 1.24px solid #122048;
            cursor: pointer;
            overflow: hidden;
            margin-right: 5px;
            margin-bottom: 5px;
            .w100 {
                width: 100%;
            }
        }
    }
}

.green-state {
    background-color: #8DC85C;
}

.red-state {
    background-color: #F1576D;
}
