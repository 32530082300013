.capacity-form {
  &__label {
    min-width: 100px;

    &--complement {
      display: block;
      font-size: 10px;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    // flex-wrap: wrap;
    margin-bottom: 16px;

    .DayPickerInput {
      margin-left: 2.5%;
      position: relative;
      width: 45%;

      &::after {
        background: url('../../ui/icons/icons/calendar.svg');
        background-size: cover;
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 17px;
        position: absolute;
        right: 10px;
        top: 49%;
        transform: translateY(-50%);
        width: 17px;
      }

      &:first-of-type {
        margin-left: 0;
      }

      input {
        align-items: center;
        border: 1px solid transparent;
        border-color: #e5e9f2;
        // -webkit-appearance: none;
        border-radius: 4px;
        box-shadow: none;
        color: #363636;
        display: inline-flex;
        font-size: 1rem;
        height: 2.5em;
        justify-content: flex-start;
        line-height: 1.5;
        padding-bottom: calc(0.5em - 1px);
        padding-left: calc(0.75em - 1px);
        padding-right: calc(0.75em - 1px);
        padding-top: calc(0.5em - 1px);
        position: relative;
        vertical-align: top;
        width: 100%;
      }
    }

    .input {
      width: 130px;
    }
  }
}
