.invoice-edit {
  &__header {
    padding: 40px 40px 10px 40px !important;
  }

  &__detail {
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    background-color: #ffffff;
  }

  &__body {
    tr {
      th {
        padding-top: 19px;
        padding-bottom: 19px;
      }

      th:first-child {
        padding-left: 40px;
      }

      th:last-child {
        padding-right: 40px;
      }
    }
  }

  &__summary {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
