@charset "UTF-8";
.view-port .is-fullheight {
  height: 94%; }

.approvals-list-container {
  height: calc(100vh - 200px);
  border-radius: 3px; }
  .approvals-list-container .turnboard-virtualized-list:focus-visible {
    outline: none !important; }
  .approvals-list-container .turnboard-header-wrapper {
    padding: 16px;
    display: flex;
    width: 100%;
    align-items: center; }
    .approvals-list-container .turnboard-header-wrapper h2 {
      margin: 0px;
      color: #122048;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.36px;
      line-height: 22px; }
    .approvals-list-container .turnboard-header-wrapper > button {
      text-transform: capitalize;
      margin-left: 10px;
      border: 1px solid #e5e9f2;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #122048;
      padding: 9px; }
      .approvals-list-container .turnboard-header-wrapper > button img {
        margin-right: 8px; }
    .approvals-list-container .turnboard-header-wrapper .custom-icon {
      width: 20px;
      height: 20px; }
    .approvals-list-container .turnboard-header-wrapper .dropdown-action-button {
      margin-left: 10px;
      cursor: pointer;
      position: relative; }
      .approvals-list-container .turnboard-header-wrapper .dropdown-action-button .dropdown .dropdown-trigger button {
        padding: 0px !important;
        border: 0px !important; }
      .approvals-list-container .turnboard-header-wrapper .dropdown-action-button .dropdown .dropdown-menu a {
        color: #122048;
        font-size: 12px;
        padding: 9px 16px !important; }
  .approvals-list-container .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff; }
    .approvals-list-container .data-table-header .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .approvals-list-container .data-table-header .action-button button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px; }
      .approvals-list-container .data-table-header .action-button .is-success:hover {
        background-color: #86c453 !important; }
      .approvals-list-container .data-table-header .action-button .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0; }
        .approvals-list-container .data-table-header .action-button .export img {
          margin-right: 8px;
          margin-top: -2px; }
      .approvals-list-container .data-table-header .action-button .inspection-action-buttons button {
        margin-right: 12px; }
      .approvals-list-container .data-table-header .action-button .inspection-action-buttons .is-primary-outlined {
        color: #3db3e2;
        border: 1px solid #3db3e2; }
    .approvals-list-container .data-table-header .more-action-button {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px; }
      .approvals-list-container .data-table-header .more-action-button .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        cursor: pointer;
        border: 0; }
        .approvals-list-container .data-table-header .more-action-button .export img {
          margin-right: 8px;
          margin-top: -2px; }
  .approvals-list-container .applied-filters-wrapper {
    display: flex;
    padding: 15px 20px; }
  .approvals-list-container .scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: scroll; }
  .approvals-list-container .turnboard-table-wrapper {
    width: 100%;
    height: calc(100vh - 215px);
    display: flex;
    overflow: scroll;
    position: relative; }
    .approvals-list-container .turnboard-table-wrapper::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
      height: 7px;
      position: absolute;
      right: 0; }
    .approvals-list-container .turnboard-table-wrapper::-webkit-scrollbar-track {
      box-shadow: transparent;
      background-color: transparent; }
    .approvals-list-container .turnboard-table-wrapper::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 7px;
      outline: none; }
    .approvals-list-container .turnboard-table-wrapper .is-pointer {
      cursor: pointer; }
    .approvals-list-container .turnboard-table-wrapper .custom-checkbox {
      width: 12px;
      margin: 0; }
    .approvals-list-container .turnboard-table-wrapper .checkbox {
      margin: 0; }
    .approvals-list-container .turnboard-table-wrapper .checkbox-inner {
      z-index: 0;
      width: 12px;
      height: 12px;
      border: 1px solid #122048;
      border-radius: 0; }
      .approvals-list-container .turnboard-table-wrapper .checkbox-inner:after {
        height: 6px;
        width: 2px;
        left: 4px;
        top: 1px; }
    .approvals-list-container .turnboard-table-wrapper .checkbox-label {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px; }
    .approvals-list-container .turnboard-table-wrapper .is-icon {
      height: 14px;
      width: 14px; }
    .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column; }
      .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title {
        color: #ffffff;
        width: 100%;
        text-align: center;
        padding: 15px 0px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 16px;
        position: relative; }
        .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title span {
          position: absolute;
          right: 20px;
          top: 15px;
          cursor: pointer; }
        .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title.collapsed {
          min-width: 20px;
          text-align: center; }
          .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title.collapsed span {
            position: relative;
            top: 0px;
            right: 0px; }
      .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .first-table .rdt_TableHeadRow {
        padding-left: 0; }
      .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper {
        border: 0px !important; }
        .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_TableCol,
        .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_TableCell {
          min-width: 50px;
          text-align: center;
          padding: 0px 10px; }
        .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_TableCol {
          padding: 10px; }
        .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_Table {
          border-color: #122048 !important;
          border-left: 0px !important; }
      .approvals-list-container .turnboard-table-wrapper .turnboard-item-wrapper.collapsed .data-table-container {
        border-right: 1px solid #122048;
        height: 100%; }

.approvals-table {
  overflow: auto; }
  .approvals-table::-webkit-scrollbar {
    height: 6px;
    width: 6px; }
  .approvals-table::-webkit-scrollbar-track {
    background: transparent; }
  .approvals-table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .approvals-table::-webkit-scrollbar-thumb:hover {
    background: #555; }
  .approvals-table .inner-header .tr:first-child {
    display: none !important; }
  .approvals-table .tr:first-child .th {
    padding: 0px !important; }
  .approvals-table .tr .th {
    padding: 10px !important; }
  .approvals-table .tr:hover .td:last-child .menu-item {
    display: block !important; }
  .approvals-table .th {
    color: #122048;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px; }
    .approvals-table .th .table-header {
      display: flex;
      align-items: center; }
    .approvals-table .th select {
      margin-top: 8px !important;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e9f2;
      margin: 0px auto;
      outline: none;
      font-size: 12px;
      height: 23px; }
    .approvals-table .th .header-item {
      text-align: center;
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      color: #ffffff !important;
      position: relative; }
      .approvals-table .th .header-item span {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer; }
      .approvals-table .th .header-item.collapsed {
        min-width: 20px;
        text-align: center; }
        .approvals-table .th .header-item.collapsed span {
          right: 0px;
          top: 0px;
          position: relative; }
  .approvals-table .td {
    font-size: 12px; }
    .approvals-table .td:first-child {
      padding: 3px !important; }
    .approvals-table .td:last-child .menu-item {
      display: none !important; }
    .approvals-table .td .cell-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 15px; }
      .approvals-table .td .cell-item .disabled-row {
        color: #91989e !important;
        opacity: 0.7; }
    .approvals-table .td .menu-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      font-size: 14px;
      line-height: 15px; }
    .approvals-table .td .link {
      color: #3db3e2;
      text-decoration-line: underline;
      line-height: 15px;
      cursor: pointer; }
  .approvals-table .field-input {
    margin-top: -13px; }
    .approvals-table .field-input input {
      width: 100%;
      box-sizing: border-box;
      height: 15px !important;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      padding: 11px 5px;
      outline: none;
      box-shadow: none;
      font-family: Avenir; }
      .approvals-table .field-input input:hover {
        border-color: #b5b5b5; }
      .approvals-table .field-input input:focus {
        border-color: #b5b5b5; }
      .approvals-table .field-input input::placeholder {
        color: #8e8e8e !important;
        opacity: 1; }
      .approvals-table .field-input input ::–webkit-input-placeholder {
        color: #8e8e8e !important;
        opacity: 1; }
  .approvals-table .full-body::-webkit-scrollbar {
    width: 6px; }
  .approvals-table .full-body::-webkit-scrollbar-track {
    background: transparent; }
  .approvals-table .full-body::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .approvals-table .full-body::-webkit-scrollbar-thumb:hover {
    background: #555; }

.invoice-icon img {
  width: 20px;
  height: 20px;
  margin-top: -3px; }

.is-approved {
  color: #8dc85c; }

.table-sort {
  vertical-align: middle;
  display: inline-block;
  margin-left: 5px;
  margin-top: -4px; }

.single-damage .select {
  width: 100% !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer !important; }

.single-damage .select:not(.is-multiple) {
  height: 2.9em !important; }

.single-damage .damage-field-select select {
  box-sizing: border-box;
  cursor: pointer !important;
  width: 100% !important;
  height: 20px !important;
  border-radius: 3px;
  border: none !important;
  padding: 0px 5px !important;
  outline: none;
  box-shadow: none;
  font-family: Avenir;
  box-shadow: none !important; }
  .single-damage .damage-field-select select::placeholder {
    color: #8e8e8e !important;
    opacity: 1; }
  .single-damage .damage-field-select select ::–webkit-input-placeholder {
    color: #8e8e8e !important;
    opacity: 1; }
  .single-damage .damage-field-select select ::after {
    top: 10px !important; }
  .single-damage .damage-field-select select:disabled {
    cursor: not-allowed !important; }
