@charset "UTF-8";
.invite-user {
  font-family: Avenir;
  color: #122048; }
  .invite-user .input-set {
    margin-bottom: 10px; }
  .invite-user .input-field input.form-control {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    padding: 13px 10px;
    outline: none;
    box-shadow: none;
    font-family: Avenir; }
    .invite-user .input-field input.form-control:hover {
      border-color: #b5b5b5; }
    .invite-user .input-field input.form-control:focus {
      border-color: #b5b5b5; }
  .invite-user .input-field input::placeholder {
    color: #8e8e8e !important;
    opacity: 1;
    font-size: 13px !important; }
  .invite-user .input-field input ::–webkit-input-placeholder {
    color: #8e8e8e !important;
    opacity: 1;
    font-size: 13px !important; }
  .invite-user .input-field textarea.form-control {
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    padding: 13px 10px;
    outline: none;
    box-shadow: none;
    font-family: Avenir; }
    .invite-user .input-field textarea.form-control:hover {
      border-color: #b5b5b5; }
    .invite-user .input-field textarea.form-control:focus {
      border-color: #b5b5b5; }
  .invite-user .input-field select {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    font-size: 14px;
    padding: 10px 10px;
    outline: none; }
    .invite-user .input-field select:focus {
      border-color: #3db3e2; }
  .invite-user .input-field .css-14el2xx-placeholder {
    font-size: 14px; }
  .invite-user .input-field .css-1s2u09g-control {
    border-color: #e5e9f2 !important; }
  .invite-user .required-star {
    color: red; }
  .invite-user .password-label {
    padding-left: 12px;
    vertical-align: text-bottom;
    cursor: pointer; }
  .invite-user .button-wrapper {
    margin-top: 11px !important;
    display: flex;
    justify-content: center; }
  .invite-user .permission-container {
    max-height: 200px;
    overflow-y: scroll; }
    .invite-user .permission-container::-webkit-scrollbar {
      width: 6px; }
    .invite-user .permission-container::-webkit-scrollbar-track {
      background: transparent; }
    .invite-user .permission-container::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px; }
    .invite-user .permission-container::-webkit-scrollbar-thumb:hover {
      background: #555; }
  .invite-user .permission-set-helper-container {
    display: flex;
    justify-content: space-between; }
  .invite-user .permission-label {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    font-weight: bold; }
  .invite-user .permission-bg {
    background-color: #f8f9fa; }
  .invite-user .permission-set {
    padding: 10px; }
  .invite-user .permission-field-set {
    padding-top: 5px;
    display: flex;
    justify-content: space-between; }
    .invite-user .permission-field-set .permission-field {
      width: 32%; }
  .invite-user .link {
    color: #3db3e2;
    text-decoration-line: underline;
    line-height: 15px;
    cursor: pointer; }
  .invite-user .label {
    margin-top: 7px !important;
    font-weight: normal !important; }
  .invite-user .password-error {
    padding-left: 13px; }
  .invite-user .error-button {
    margin-bottom: auto; }
  .invite-user .mb-0 {
    margin-bottom: 0px !important; }
  .invite-user .user-permission-container {
    border: 1px solid #e5e9f2;
    padding: 10px 12px 3px 12px; }
  .invite-user .label-heading {
    font-weight: 600; }
  .invite-user .error-top-spacing {
    margin-top: -9px !important; }
  .invite-user .permission-delete {
    cursor: pointer;
    margin-right: -8px !important; }
  .invite-user .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important; }
  .invite-user .toggle-container {
    margin-top: 6px !important; }
