.turnable-spinner {
  &.small {
    height: 30px;
  }
  &.medium {
    height: 60px;
  }
  &.large {
    height: 120px;
  }

  animation: rotation 2s infinite linear;
  transform-origin: center;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
