.qc-main {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 70px;
  grid-template-areas: 
    'main'
    'footer';

  &-content {
    grid-area: main;
    overflow: auto;
  }
}