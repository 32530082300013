.mobile-sumary-order {
  padding: 24px 16px 16px 16px;
  box-shadow: 0 2px 4px 0 #E5E9F2;


  &-tag {
    min-width: 91px;
  }

  &-unit-content {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  &-unit-action {
    display: flex;
  }

  &-unit {
    flex: 1;
  }

  &-unit-title {
    font-size: 24px;
    font-weight: 300;
    padding-top: 2px;
    color: #122048;
  }

  &-action {
    background-color: transparent;
    border-width: 0;
  }
}