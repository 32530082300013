.ellipsis-button {
  white-space: nowrap;
}
/* Dropdown Button */
.drop-menu {
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown-menu-container {
  position: relative;
  display: inline-block;
}

// .button {
//   &:hover {
//     background-color: transparent !important;
//   }
// }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 170px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    &:hover {
      background-color: #ddd;
    }
  }
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

.selected {
  background-color: #f1f1f1;
}

.is-pointer {
  cursor: pointer;
}

.tippy-tooltip {
  &.menu-theme {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    background-color: #fff;
    color: #26323d;
    padding: 0;
  }
}

.table-action-menu {
  .tippy-content {
    padding: 0;
  }
}
