.schedule-table {
  border: 1px solid #e5e9f2;
  width: 100%;

  .arrow {
    left: 15px;
    position: absolute;
    top: 18px;
  }

  .collapsible-row {
    display: none;

    &.active {
      display: table-row;
    }
  }

  tr {
    border: 1px solid #e5e9f2;
  }

  th,
  td {
    color: #122048;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    padding: 14px;
    text-align: center !important;
    position: relative;
    min-width: 145px;

    &:first-of-type {
      padding-left: 40px;
    }

    &:last-of-type {
      padding-right: 40px;
    }
  }

  thead {
    tr {
      background: #3DB3E2;
      border: 1px solid #e5e9f2;
      
      th {
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }
}
