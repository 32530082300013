.inspection-fileupload-modal {
  font-family: Avenir;
  color: #122048;
  p {
    &.modal-sync-title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }
  .modal-card-body {
    padding: 31px 40px 40px !important;
  }
  .upload-file-inner {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C1C5C9FF' stroke-width='2' stroke-dasharray='6%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
    // border: 1px dashed #C1C5C9;
    border-radius: 2;
    background-color: #FFFFFF;
    outline: none;
    // transition: border .24s ease-in-out;
    text-align: center;
    color: #122048;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 19px;
    .list-group {
      height: 190px;
      overflow: hidden;
      overflow-y: scroll;
      .list-group-item {
        margin-right: 12px;
        margin-bottom: 5px;
      }
    }
    .upload-file-area {
      padding: 15px 24px 24px;
      img {
        margin-bottom: 8px;
      }
      p {
        color: #122048;
        font-family: Avenir;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 29px;
        margin-bottom: 10px;
      }
      em {
        display: block;
        font-size: 11px;
        color: #C1C5C9;
      }
    }
    .uploaded-file {
      height: 131px;
      width: 100px;
      border: 1px solid #E5E9F2;
      background-color: #FFFFFF;
      position: relative;
      justify-content: center;
      button {
        padding: 0px;
        border: 0;
        background-color: transparent;
        position: absolute;
        right: -11px;
        top: 0;
        cursor: pointer;
      }
    }
  }
  .action-items {
    width: 100%;
    margin-top: 15px;
    .action-items-buttons {
      margin-left: auto;
      button {
        max-width: 100%;
        width: 127px;
        font-size: 16px !important;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
        margin-left: 12px;
      }
      .is-outlined {
        border-radius: 4px;
        border-color: #3DB3E2;
        color: #3DB3E2;
      }
    }
  }
  .notification {
    align-items: center;
    padding: 10px 15px;
    font-size: 12px;
    margin-top: 10px;
    button {
      background-color: transparent;
      border: none;
      padding: 0px;
      cursor: pointer;
      margin-left: auto;
    }
  }
}






