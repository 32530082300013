.setup-staff-main {
  .ellipsis-container {
    position: relative;

    .ellipsis {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

.vendor-section {
  .breadcrumb {
    margin-bottom: 15px !important;
  }
  .back-text {
    margin-bottom: 15px;
  }
}
