div.stars {
  li {
    svg {
      color: grey;
    }
    &.is-active {
      svg {
        color: gold;
      }
    }
  }
}
