@charset "UTF-8";
.add-vendor-login-modal {
  font-family: Avenir;
  color: #122048; }
  .add-vendor-login-modal p.modal-sync-title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400; }
  .add-vendor-login-modal .modal-card {
    overflow: initial !important; }
    .add-vendor-login-modal .modal-card .modal-card-body::-webkit-scrollbar {
      width: 8px; }
    .add-vendor-login-modal .modal-card .modal-card-body::-webkit-scrollbar-track {
      background: transparent; }
    .add-vendor-login-modal .modal-card .modal-card-body::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px; }
    .add-vendor-login-modal .modal-card .modal-card-body::-webkit-scrollbar-thumb:hover {
      background: #555; }
  .add-vendor-login-modal .field-container {
    margin-top: -10px; }
    .add-vendor-login-modal .field-container .field-text {
      margin-bottom: 8px; }
      .add-vendor-login-modal .field-container .field-text .label {
        font-size: 16px;
        font-weight: normal; }
    .add-vendor-login-modal .field-container .field-input input {
      width: 100%;
      box-sizing: border-box;
      height: 35px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      padding: 13px 10px;
      outline: none;
      box-shadow: none;
      font-family: Avenir; }
      .add-vendor-login-modal .field-container .field-input input:hover {
        border-color: #b5b5b5; }
      .add-vendor-login-modal .field-container .field-input input:focus {
        border-color: #b5b5b5; }
      .add-vendor-login-modal .field-container .field-input input::placeholder {
        color: #8e8e8e !important;
        opacity: 1; }
      .add-vendor-login-modal .field-container .field-input input ::–webkit-input-placeholder {
        color: #8e8e8e !important;
        opacity: 1; }
  .add-vendor-login-modal .email-exists-container {
    margin-top: -10px; }
    .add-vendor-login-modal .email-exists-container .email-exists-message {
      font-size: 15px; }
    .add-vendor-login-modal .email-exists-container .email-exists-sub-message {
      font-size: 15px;
      margin-top: 15px; }
  .add-vendor-login-modal .vendor-toggle-container {
    margin-top: -10px;
    margin-bottom: 4rem !important; }
    .add-vendor-login-modal .vendor-toggle-container .vendor-toggle {
      font-size: 15px;
      display: flex;
      gap: 1rem; }

.vendor-toggle-control .toggle-control {
  margin-bottom: 0px; }
  .vendor-toggle-control .toggle-control input:checked ~ .control {
    background-color: #3cb3e2; }
    .vendor-toggle-control .toggle-control input:checked ~ .control:after {
      left: 24px; }
  .vendor-toggle-control .toggle-control .control {
    width: 50px !important;
    height: 25px !important; }

.vendor-toggle-control .toggle-control .control:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: white;
  transition: left 0.15s ease-in; }

.error-message-mb {
  margin-top: 12px !important;
  margin-bottom: 0px !important; }
