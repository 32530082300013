html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}
