$primaryColor: #122048;
$dangerColor: #f1576d;
$secondaryColor: #3db3e2;
$activeColor: #8dc85c;
.quotation {
  margin-top: 24px;
  font-family: Avenir;
  .mt-30 {
    margin-top: 30px;
  }
  .go-back-button {
    background-color: transparent;
    border: 0;
    color: $secondaryColor;
    font-size: 14px;
    line-height: 19px;
    padding: 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .top-header {
    margin-top: 6px;
    .top-header-container {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 24px;
        line-height: 33px;
        color: $primaryColor;
        font-weight: 700;
      }
      .deadline {
        display: inline-block;
        padding: 8px 16px;
        background-color: $dangerColor;
        border: 1px solid transparent;
        color: #ffffff;
        border-radius: 18px;
        font-size: 14px;
        line-height: 17px;
      }
      .submitted-at {
        display: inline-block;
        padding: 8px 16px;
        background-color: $activeColor;
        border: 1px solid transparent;
        color: #ffffff;
        border-radius: 18px;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

.progress-section-wrapper {
  margin-top: 30px;
  display: block;
  font-family: Avenir;
  h2 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 25px;
  }
  .progress-heading {
    color: $primaryColor;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 26px;
    font-weight: 700;
  }
  .progress-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    .progress-item {
      padding-bottom: 35px;
      position: relative;
      span {
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: $primaryColor;
        padding-top: 2px;
        &::before {
          content: '';
          position: relative;
          width: 20px;
          top: -1px;
          height: 20px;
          border: 1px solid $secondaryColor;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 40px;
        display: inline-block;
        left: 10px;
        bottom: -3px;
        background-color: $secondaryColor;
      }
      &:last-child::after {
        display: none;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .activeTab {
      span {
        &::before {
          border: 1px solid $activeColor;
        }
      }
    }
    .completedTab {
      span {
        &::before {
          background-color: $activeColor;
          border-color: $activeColor;
          background-image: url(./icons/completed-tick.svg);
          background-position: 1px 1px;
          background-repeat: no-repeat;
        }
      }
      &::after {
        background-color: $activeColor;
        border-color: $activeColor;
      }
    }
  }
}
