.rfp-modal {
  p {
    &.modal-sync-title {
      font-family: Avenir;
      color: #122048;
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }

  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      overflow: initial !important;
    }
  }
}

.alert {
  width: 100%;
  border-radius: 3px;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  label {
    color: #122048;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
  }
  .input-field {
    .react-datepicker-wrapper {
      width: 100%;
      input {
        width: 100%;
        box-sizing: border-box;
        height: 45px;
        border-radius: 3px;
        border: 1px solid #8d8f94;
        padding: 10px 10px;
        outline: none;
        font-size: 14px;
        color: #122048;
        &:focus {
          border-color: #3db3e2;
        }
      }
      .icon.is-right {
        top: 10px;
        z-index: 0;
      }
    }
    select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #8d8f94;
      font-size: 14px;
      padding: 10px 10px;
      outline: none;
      color: #122048;
      &:focus {
        border-color: #3db3e2;
      }
    }
    .css-14el2xx-placeholder {
      font-size: 14px;
      color: #122048;
    }
    .react-datepicker__input-container {
      input {
        font-size: 14px;
        color: #122048;
        &::placeholder {
          color: #122048 !important;
          opacity: 1;
        }
      }
    }
  }
}

.help-block {
  &.text-danger {
    color: #f14668;
    text-transform: capitalize;
  }
}
