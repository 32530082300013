@charset "UTF-8";
.setup-Staff-user-stepper-modal .sub-message {
  margin-top: 30px;
  text-align: center; }

.setup-Staff-user-stepper-modal .input-label {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.36px; }

.setup-Staff-user-stepper-modal .input-label-two {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 7.5px; }

.setup-Staff-user-stepper-modal input.form-control {
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  border-radius: 3px;
  border: 1px solid #e5e9f2;
  padding: 13px 10px;
  outline: none;
  box-shadow: none;
  font-family: Avenir; }
  .setup-Staff-user-stepper-modal input.form-control:hover {
    border-color: #b5b5b5; }
  .setup-Staff-user-stepper-modal input.form-control:focus {
    border-color: #b5b5b5; }

.setup-Staff-user-stepper-modal input::placeholder {
  color: #8e8e8e !important;
  opacity: 1; }

.setup-Staff-user-stepper-modal input ::–webkit-input-placeholder {
  color: #8e8e8e !important;
  opacity: 1; }
