.invoice-send-modal {
    &__input {
        display: flex;
        margin-bottom: 8px;

        & label {
            min-width: 51px;
            text-align: right;
            margin-right: 8px;
        }

        & textarea {
            min-width: 100px;
            resize: none;
        }
    }

    &__title {
        font-size: 24px;
    }

    &__order-number {
        color: #55c2b8;
    }
}
