$primary: #000;
$hover: #0081c3;
$selected: #3db3e2;
$tablet: 769px;
.react-table-pagination {
  border-top: 1px solid #e5e9f2;
}
.row-per-page {
  padding: 14px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: $tablet) {
    padding: 0 24px;
    padding-top: 11px;
    justify-content: left;
  }
}
.table-pagination-wrapper {
  width: 100%;
  padding: 10px 24px;
  display: block;
  @media screen and (min-width: $tablet) {
    display: flex;
    justify-content: space-between;
  }
  background-color: #ffffff;
  .pagination-count {
    text-align: center;
    padding-bottom: 20px;
    @media screen and (min-width: $tablet) {
      display: flex;
      align-items: center;
      padding: 0;
    }
  }
  .pages {
    .pagination {
      .page-item {
        button {
          min-width: 25px !important;
          color: $primary !important;
          font-weight: 100;
          font-size: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 25px;
          cursor: pointer;
          margin: 3.5px;
          background: transparent;
          border: 0px;
          cursor: pointer;
          &:disabled {
            color: #7a7a7a !important;
            opacity: 0.5;
          }
          .page-link {
            cursor: pointer;
          }
        }
        .next-page,
        .previous-page {
          &:hover {
            color: #000 !important;
          }
        }
      }
    }
  }

  .paginator-container {
    display: flex;
    align-items: center;
  }
  .pagination-link,
  .pagination-next,
  .pagination-previous {
    background-color: #fff;
    border: none;
    box-shadow: none !important;
  }

  .pagination-link {
    min-width: 25px !important;
    color: $primary;
    font-weight: 100;
    font-size: 1rem;
    height: 25px;
    &:hover {
      color: $hover;
    }
  }

  .pagination-ellipsis {
    color: #626a6a;
  }

  .pagination-next,
  .pagination-previous {
    color: #000;
    &:hover {
      color: #000 !important;
    }
  }
  .is-current {
    background-color: $selected !important;
    border-radius: 50% !important;
    color: white !important;
    &:hover {
      background-color: lighten($selected, 5%) !important;
      color: white !important;
    }
  }
}
.counter-section {
  text-align: center;

  @media screen and (min-width: $tablet) {
    text-align: left;
  }
}
