.modal-sync {
  &-message {
    color: #5db3df;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 8px;
    text-align: center;
  }

  &-note {
    font-size: 12px;
    font-style: oblique;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }

  &-subtitle {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;
  }

  &-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 33px;
  }
}
