@charset "UTF-8";
.invite-staff {
  font-family: Avenir;
  color: #122048; }
  .invite-staff .input-set {
    margin-bottom: 10px; }
  .invite-staff .input-field input.form-control, .invite-staff .input-field textarea.form-control {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    padding: 13px 10px;
    outline: none;
    box-shadow: none;
    font-family: Avenir; }
    .invite-staff .input-field input.form-control:hover, .invite-staff .input-field textarea.form-control:hover {
      border-color: #b5b5b5; }
    .invite-staff .input-field input.form-control:focus, .invite-staff .input-field textarea.form-control:focus {
      border-color: #b5b5b5; }
  .invite-staff .input-field input::placeholder, .invite-staff .input-field textarea::placeholder {
    color: #8e8e8e !important;
    opacity: 1; }
  .invite-staff .input-field input ::–webkit-input-placeholder, .invite-staff .input-field textarea ::–webkit-input-placeholder {
    color: #8e8e8e !important;
    opacity: 1; }
  .invite-staff .input-field textarea {
    padding: 6px 10px !important; }
  .invite-staff .input-field select {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    font-size: 14px;
    padding: 10px 10px;
    outline: none; }
    .invite-staff .input-field select:focus {
      border-color: #3db3e2; }
  .invite-staff .input-field .css-14el2xx-placeholder {
    font-size: 14px; }
  .invite-staff .input-field .css-1s2u09g-control {
    border-color: #e5e9f2 !important; }
  .invite-staff .required-star {
    color: red; }
  .invite-staff .password-label {
    padding-left: 12px;
    vertical-align: text-bottom;
    cursor: pointer; }
  .invite-staff .button-wrapper {
    margin-top: 11px !important;
    display: flex;
    justify-content: center; }
  .invite-staff .link {
    color: #3db3e2;
    text-decoration-line: underline;
    line-height: 15px;
    cursor: pointer; }
  .invite-staff .label {
    margin-top: 7px !important;
    font-weight: normal !important; }
  .invite-staff .password-error {
    padding-left: 13px; }
  .invite-staff .error-button {
    margin-bottom: auto; }
  .invite-staff .mb-0 {
    margin-bottom: 0px !important; }
  .invite-staff .label-heading {
    font-weight: 600; }
  .invite-staff .error-top-spacing {
    margin-top: -9px !important; }
  .invite-staff .permission-delete {
    cursor: pointer;
    margin-right: -8px !important; }
  .invite-staff .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important; }
