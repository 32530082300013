.edition-list {
  &__details {
    width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    padding-right: 10px;
    margin-top: 15px;

    &__name {
      text-transform: capitalize;
      width: 300px;
    }

    &__status {
      display: flex;
      align-items: center;

      img {
        margin-left: 2px;
      }
    }
  }

  &__tooltip {
    padding: 15px;
    box-sizing: border-box;
    height: 207px;
    width: 312px;

    .inactive-text {
      margin-top: 20px;
    }
  }

  &__body {
    table {
      min-width: 600px;
      width: 100%;

      thead {
        text-transform: capitalize;
      }
    }
  }

  &__container {
    padding: 0 14.5px;

    .button {
      &__transparent {
        border: 0;
        background: transparent;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
        text-transform: capitalize;
      }
    }

    .btn-save {
      width: 127px;
    }
  }

  &__description {
    margin: 16px 0 0;
    max-width: 75%;
    font-size: 15px;
  }

  &__header {
    margin: 14.5px 24.5px 0 0;
  }

  &__item {
    margin-bottom: 12px;
  }

  &__title {
    color: #122048;
    display: inline-block;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 29px;
    margin-right: 24.5px;
    text-transform: capitalize;
  }

  &__heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
  }
}

.edition-list-container {
  background-color: #ffffff;
  border-radius: 3px;
  .data-table-wrapper {
    border: 0;
  }
  .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem !important;
      .custom-heading {
        color: #122048;
        font-size: 21px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 29px;
        text-transform: capitalize;
      }
      .btn-save {
        font-size: 16px;
      }
    }
    .description {
      font-size: 15px;
      margin-bottom: 1rem;
    }
    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
      }
      .is-success {
        &:hover {
          background-color: #86c453 !important;
        }
      }
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      .edition-action-buttons {
        button {
          margin-right: 12px;
        }
        .add_button {
          &:active,
          &:focus {
            border: 1px solid #e5e9f2;
          }
          &:disabled {
            cursor: not-allowed;
          }
        }
        .is-primary-outlined {
          color: #3db3e2;
          border: 1px solid #3db3e2;
        }
      }
    }
    .more-action-button {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px;
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        cursor: pointer;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
    }
  }
}
