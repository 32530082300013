$primary: #122048;
$hover: #0081c3;
$selected: #3db3e2;
$tablet: 769px;

.paginator-container {
  background-color: #fff;
  padding: 0;

  margin-left: 2px;
  margin-right: 2px;

  .counter-section {
    padding-bottom: 11px;
    padding-top: 10px;
    text-align: center;

    @media screen and (min-width: $tablet) {
      text-align: left;
    }
  }

  .pagination-link,
  .pagination-next,
  .pagination-previous {
    background-color: #fff;
    border: none;
    box-shadow: none !important;
  }

  .pagination-link {
    min-width: 25px !important;
    color: $primary;
    font-weight: 100;
    font-size: 1rem;
    height: 25px;
    &:hover {
      color: $hover;
    }
  }

  .pagination-ellipsis {
    color: #626a6a;
  }

  .pagination-next,
  .pagination-previous {
    color: #000;
    &:hover {
      color: #000;
    }
  }

  .is-current {
    background-color: $selected !important;
    border-radius: 50% !important;
    &:hover {
      background-color: lighten($selected, 5%) !important;
      color: white !important;
    }
  }
}
