@charset "UTF-8";
.adhoc-damage {
  font-family: Avenir;
  color: #122048; }
  .adhoc-damage .input-set {
    margin-bottom: 10px; }
  .adhoc-damage .input-field input.form-control {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    padding: 13px 10px;
    outline: none;
    box-shadow: none;
    font-family: Avenir; }
    .adhoc-damage .input-field input.form-control:hover {
      border-color: #b5b5b5; }
    .adhoc-damage .input-field input.form-control:focus {
      border-color: #b5b5b5; }
  .adhoc-damage .input-field input::placeholder {
    color: #8e8e8e !important;
    opacity: 1;
    font-size: 13px !important; }
  .adhoc-damage .input-field input ::–webkit-input-placeholder {
    color: #8e8e8e !important;
    opacity: 1;
    font-size: 13px !important; }
  .adhoc-damage .input-field textarea.form-control {
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    padding: 6px 10px;
    outline: none;
    box-shadow: none;
    font-family: Avenir; }
    .adhoc-damage .input-field textarea.form-control:hover {
      border-color: #b5b5b5; }
    .adhoc-damage .input-field textarea.form-control:focus {
      border-color: #b5b5b5; }
  .adhoc-damage .input-field select {
    width: 100%;
    box-sizing: border-box;
    height: 45px;
    border-radius: 3px;
    border: 1px solid #e5e9f2;
    font-size: 14px;
    padding: 10px 10px;
    outline: none; }
    .adhoc-damage .input-field select:focus {
      border-color: #3db3e2; }
  .adhoc-damage .input-field .css-14el2xx-placeholder {
    font-size: 14px; }
  .adhoc-damage .input-field .css-1s2u09g-control {
    border-color: #e5e9f2 !important; }
  .adhoc-damage .required-star {
    color: red; }
  .adhoc-damage .password-label {
    padding-left: 12px;
    vertical-align: text-bottom;
    cursor: pointer; }
  .adhoc-damage .button-wrapper {
    margin-top: 11px !important;
    display: flex;
    justify-content: center; }
  .adhoc-damage .permission-container {
    max-height: 200px;
    overflow-y: scroll; }
    .adhoc-damage .permission-container::-webkit-scrollbar {
      width: 6px; }
    .adhoc-damage .permission-container::-webkit-scrollbar-track {
      background: transparent; }
    .adhoc-damage .permission-container::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px; }
    .adhoc-damage .permission-container::-webkit-scrollbar-thumb:hover {
      background: #555; }
  .adhoc-damage .permission-set-helper-container {
    display: flex;
    justify-content: space-between; }
  .adhoc-damage .permission-label {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    font-weight: bold; }
  .adhoc-damage .permission-bg {
    background-color: #f8f9fa; }
  .adhoc-damage .permission-set {
    padding: 10px; }
  .adhoc-damage .permission-field-set {
    padding-top: 5px;
    display: flex;
    justify-content: space-between; }
    .adhoc-damage .permission-field-set .permission-field {
      width: 32%; }
  .adhoc-damage .link {
    color: #3db3e2;
    text-decoration-line: underline;
    line-height: 15px;
    cursor: pointer; }
  .adhoc-damage .label {
    margin-top: 7px !important;
    font-weight: normal !important; }
  .adhoc-damage .password-error {
    padding-left: 13px; }
  .adhoc-damage .error-button {
    margin-bottom: auto; }
  .adhoc-damage .mb-0 {
    margin-bottom: 0px !important; }
  .adhoc-damage .resident-container {
    border: 1px solid #e5e9f2;
    padding: 10px 12px 3px 12px; }
  .adhoc-damage .label-heading {
    font-weight: 600; }
  .adhoc-damage .error-top-spacing {
    margin-top: -9px !important; }
  .adhoc-damage .permission-delete {
    cursor: pointer;
    margin-right: -8px !important; }
  .adhoc-damage .button-disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important; }
  .adhoc-damage .toggle-container {
    margin-top: 6px !important; }
  .adhoc-damage .is-link {
    color: #3db3e2;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin-top: 10px;
    cursor: pointer;
    outline: 0; }
    .adhoc-damage .is-link:hover {
      text-decoration: underline; }
  .adhoc-damage .is-align-items-center {
    align-items: center !important; }
  .adhoc-damage .is-flex-wrap-wrap {
    flex-wrap: wrap !important; }
  .adhoc-damage .state-description {
    width: 100%; }
  .adhoc-damage .inspection-thumbnail-inner {
    height: 65px;
    width: 52px;
    border: 1px solid #122048;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 15px;
    margin-bottom: 5px;
    overflow-y: clip; }
    .adhoc-damage .inspection-thumbnail-inner img {
      width: 100%; }
    .adhoc-damage .inspection-thumbnail-inner button {
      position: absolute;
      top: 0;
      right: -8px;
      padding: 0px;
      border: 0px;
      background-color: transparent;
      height: 14px;
      width: 14px;
      cursor: pointer; }
      .adhoc-damage .inspection-thumbnail-inner button img {
        vertical-align: top; }
