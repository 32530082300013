$grey-lighter: hsl(0, 0%, 93%);
$link: #3eb3e2;

div.stats-card {
  height: 6rem;
  box-shadow: none;
  border: 1px solid $grey-lighter;
  padding-right: 0px;
  position: relative;

  &:hover {
    z-index: 10;
  }

  &.is-selected {
    border: 2px solid #3db3e2;
    color: #3db3e2;
    font-weight: bold;

    .extra-info {
      font-weight: bold;
    }
  }

  & > div {
    align-items: center;
    margin-bottom: 0;
    margin-top: 0;
  }

  .caption {
    font-size: 15px;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .extra-info {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 1px;
    white-space: nowrap;

    &-container {
      padding-left: 0;

      @media screen and (min-width: 1300px) {
        padding-left: 0.75rem;
      }
    }

    span {
      display: inline-block;
      line-height: 10px;
      padding-right: 8px;

      &:first-of-type {
        border-right: 1px solid #e5e9f2;
      }

      &:nth-child(2) {
        margin-top: 6px;
        padding-left: 8px;
      }
    }

    &.no-border {
      span {
        &:first-of-type {
          border-right: 0;
        }
      }
    }
  }

  &.is-selected {
    border: 1px solid $link;
  }

  div {
    max-height: 100%;
  }

  .help-container {
    right: 2px;
    top: 2px;
  }
}
