$light-blue: #3eb3e2;
$primary: #0081c3;

div.dashboard-setup {
  // max-height: 100%;
  overflow: auto;

  p.welcome {
    color: $light-blue;
    font-size: 1.6rem;
    padding-top: 5px;
  }

  p.header {
    font-size: 2.3rem;
  }

  p.header-title {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  p.recently-viewed {
    font-size: 1.6rem;
    padding-top: 5px;
  }
}

