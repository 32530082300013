@charset "UTF-8";
.add-charges-modal {
  font-family: Avenir;
  color: #122048; }
  .add-charges-modal p.modal-sync-title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400; }
  .add-charges-modal .modal-card {
    overflow: initial !important; }
    .add-charges-modal .modal-card .modal-card-body::-webkit-scrollbar {
      width: 8px; }
    .add-charges-modal .modal-card .modal-card-body::-webkit-scrollbar-track {
      background: transparent; }
    .add-charges-modal .modal-card .modal-card-body::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px; }
    .add-charges-modal .modal-card .modal-card-body::-webkit-scrollbar-thumb:hover {
      background: #555; }
  .add-charges-modal .form-group {
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 5px !important; }
    .add-charges-modal .form-group .label {
      padding-top: 23px; }
    .add-charges-modal .form-group label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 300; }
    .add-charges-modal .form-group .input-field input.form-control {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      padding: 13px 10px;
      outline: none;
      font-family: Avenir; }
      .add-charges-modal .form-group .input-field input.form-control:focus {
        border-color: #3db3e2; }
    .add-charges-modal .form-group .input-field select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      font-size: 14px;
      padding: 10px 10px;
      outline: none; }
      .add-charges-modal .form-group .input-field select:focus {
        border-color: #3db3e2; }
    .add-charges-modal .form-group .input-field .css-14el2xx-placeholder {
      font-size: 14px; }
    .add-charges-modal .form-group .input-field .css-1s2u09g-control {
      border-color: #e5e9f2 !important; }
    .add-charges-modal .form-group .input-field .react-datepicker__input-container input {
      font-size: 14px; }
      .add-charges-modal .form-group .input-field .react-datepicker__input-container input::placeholder {
        color: #122048 !important;
        opacity: 1; }
    .add-charges-modal .form-group .input-field .unit-select {
      margin-bottom: 8px; }
    .add-charges-modal .form-group .input-field .checkbox-inner {
      z-index: 0; }
    .add-charges-modal .form-group .input-field .checkbox-label {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px; }
  .add-charges-modal .note-field-spacing {
    margin-top: -18px !important; }
  .add-charges-modal .toggle-field-spacing {
    margin-top: -22px !important; }
  .add-charges-modal .m-b-space {
    margin-top: 20px !important; }
  .add-charges-modal .notification {
    align-items: center;
    padding: 10px 15px;
    font-size: 12px;
    margin-top: 10px; }
    .add-charges-modal .notification button {
      background-color: transparent;
      border: none;
      padding: 0px;
      cursor: pointer;
      margin-left: auto; }
  .add-charges-modal .sub-heading-container {
    margin: 10px 0px; }
    .add-charges-modal .sub-heading-container strong {
      font-size: 16px; }
  .add-charges-modal .main-form {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden; }
    .add-charges-modal .main-form::-webkit-scrollbar {
      width: 8px; }
    .add-charges-modal .main-form::-webkit-scrollbar-track {
      background: transparent; }
    .add-charges-modal .main-form::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px; }
    .add-charges-modal .main-form::-webkit-scrollbar-thumb:hover {
      background: #555; }
    .add-charges-modal .main-form .is-link {
      color: #3db3e2;
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
      border: 0;
      background-color: transparent;
      padding: 0;
      margin-top: 15px;
      cursor: pointer;
      outline: 0; }
      .add-charges-modal .main-form .is-link:hover {
        text-decoration: underline; }
  .add-charges-modal .resident-charge {
    font-family: Avenir;
    color: #122048; }
    .add-charges-modal .resident-charge .input-set {
      margin-bottom: 10px; }
    .add-charges-modal .resident-charge .input-field input.form-control {
      width: 100%;
      box-sizing: border-box;
      height: 35px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      padding: 13px 10px;
      outline: none;
      box-shadow: none;
      font-family: Avenir; }
      .add-charges-modal .resident-charge .input-field input.form-control:hover {
        border-color: #b5b5b5; }
      .add-charges-modal .resident-charge .input-field input.form-control:focus {
        border-color: #b5b5b5; }
    .add-charges-modal .resident-charge .input-field input::placeholder {
      color: #8e8e8e !important;
      opacity: 1;
      font-size: 13px !important; }
    .add-charges-modal .resident-charge .input-field input ::–webkit-input-placeholder {
      color: #8e8e8e !important;
      opacity: 1;
      font-size: 13px !important; }
    .add-charges-modal .resident-charge .input-field textarea.form-control {
      width: 100%;
      box-sizing: border-box;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      padding: 6px 10px;
      outline: none;
      box-shadow: none;
      font-family: Avenir; }
      .add-charges-modal .resident-charge .input-field textarea.form-control:hover {
        border-color: #b5b5b5; }
      .add-charges-modal .resident-charge .input-field textarea.form-control:focus {
        border-color: #b5b5b5; }
    .add-charges-modal .resident-charge .input-field select {
      width: 100%;
      box-sizing: border-box;
      height: 45px;
      border-radius: 3px;
      border: 1px solid #e5e9f2;
      font-size: 14px;
      padding: 10px 10px;
      outline: none; }
      .add-charges-modal .resident-charge .input-field select:focus {
        border-color: #3db3e2; }
    .add-charges-modal .resident-charge .input-field .css-14el2xx-placeholder {
      font-size: 14px; }
    .add-charges-modal .resident-charge .input-field .css-1s2u09g-control {
      border-color: #e5e9f2 !important; }
    .add-charges-modal .resident-charge .required-star {
      color: red; }
    .add-charges-modal .resident-charge .password-label {
      padding-left: 12px;
      vertical-align: text-bottom;
      cursor: pointer; }
    .add-charges-modal .resident-charge .button-wrapper {
      margin-top: 11px !important;
      display: flex;
      justify-content: center; }
    .add-charges-modal .resident-charge .permission-container {
      max-height: 200px;
      overflow-y: scroll; }
      .add-charges-modal .resident-charge .permission-container::-webkit-scrollbar {
        width: 6px; }
      .add-charges-modal .resident-charge .permission-container::-webkit-scrollbar-track {
        background: transparent; }
      .add-charges-modal .resident-charge .permission-container::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px; }
      .add-charges-modal .resident-charge .permission-container::-webkit-scrollbar-thumb:hover {
        background: #555; }
    .add-charges-modal .resident-charge .permission-set-helper-container {
      display: flex;
      justify-content: space-between; }
    .add-charges-modal .resident-charge .permission-label {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0 !important;
      font-weight: bold; }
    .add-charges-modal .resident-charge .permission-bg {
      background-color: #f8f9fa; }
    .add-charges-modal .resident-charge .permission-set {
      padding: 10px; }
    .add-charges-modal .resident-charge .permission-field-set {
      padding-top: 5px;
      display: flex;
      justify-content: space-between; }
      .add-charges-modal .resident-charge .permission-field-set .permission-field {
        width: 32%; }
    .add-charges-modal .resident-charge .link {
      color: #3db3e2;
      text-decoration-line: underline;
      line-height: 15px;
      cursor: pointer; }
    .add-charges-modal .resident-charge .label {
      margin-top: 7px !important;
      font-weight: normal !important; }
    .add-charges-modal .resident-charge .password-error {
      padding-left: 13px; }
    .add-charges-modal .resident-charge .error-button {
      margin-bottom: auto; }
    .add-charges-modal .resident-charge .mb-0 {
      margin-bottom: 0px !important; }
    .add-charges-modal .resident-charge .user-permission-container {
      border: 1px solid #e5e9f2;
      padding: 10px 12px 3px 12px; }
    .add-charges-modal .resident-charge .label-heading {
      font-weight: 600; }
    .add-charges-modal .resident-charge .error-top-spacing {
      margin-top: -9px !important; }
    .add-charges-modal .resident-charge .permission-delete {
      cursor: pointer;
      margin-right: -8px !important; }
    .add-charges-modal .resident-charge .button-disabled {
      opacity: 0.5 !important;
      cursor: not-allowed !important; }
    .add-charges-modal .resident-charge .toggle-container {
      margin-top: 6px !important; }
    .add-charges-modal .resident-charge .is-link {
      color: #3db3e2;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
      border: 0;
      background-color: transparent;
      padding: 0;
      margin-top: 10px;
      cursor: pointer;
      outline: 0; }
      .add-charges-modal .resident-charge .is-link:hover {
        text-decoration: underline; }
    .add-charges-modal .resident-charge .is-align-items-center {
      align-items: center !important; }
    .add-charges-modal .resident-charge .is-flex-wrap-wrap {
      flex-wrap: wrap !important; }
    .add-charges-modal .resident-charge .state-description {
      width: 100%; }
    .add-charges-modal .resident-charge .inspection-thumbnail-inner {
      height: 65px;
      width: 52px;
      border: 1px solid #122048;
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 15px;
      margin-bottom: 5px;
      overflow-y: clip; }
      .add-charges-modal .resident-charge .inspection-thumbnail-inner img {
        width: 100%; }
      .add-charges-modal .resident-charge .inspection-thumbnail-inner button {
        position: absolute;
        top: 0;
        right: -8px;
        padding: 0px;
        border: 0px;
        background-color: transparent;
        height: 14px;
        width: 14px;
        cursor: pointer; }
        .add-charges-modal .resident-charge .inspection-thumbnail-inner button img {
          vertical-align: top; }
