.vendor-header-user {
  color: #122048;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 8px;
}

.vendor-header-user-message {
  color: #122048;
  font-size: 18px;
  font-weight: 300;
}
