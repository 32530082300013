.toggle-control {
  margin-bottom: -9px;
  input:checked ~ .control {
    background-color: #3db3e2;
    &:after {
      left: 20px;
    }
  }
  .control {
    width: 40px !important;
    height: 20px !important;
  }
}
