$light-blue: #3eb3e2;
$primary: #0081c3;

div.dashboard-box {
  &:hover {
    box-shadow: 0 0.5rem 1rem -0.125rem rgba(10, 10, 10, 0.3),
      0 0px 0 1px rgba(10, 10, 10, 0.04);
    transition: box-shadow 500ms;
  }

  svg {
    color: $light-blue;
  }

  p.header {
    font-size: 2.47rem;
  }

  p.caption {
    font-size: 13px;
  }

  button.lets-go {
    &.secondary {
      background-color: #fff;
      border: 1px solid $primary;
      color: $light-blue;
    }
  }
}
