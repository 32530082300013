$grey-lighter: hsl(0, 0%, 93%);
$link: #3eb3e2;

div.service-card {
  height: auto !important;
  box-shadow: none;
  border: 1px solid $grey-lighter;

  &.box {
    padding: 0 24px;
  }

  &.is-selected {
    border: 1px solid $link;
  }

  &-detail {
    height: 0;
    overflow: hidden;

    &.open {
      height: auto;
      overflow: visible;
    }
  }

  &-header {
    align-items: center;
    color: #0081c3;
    display: flex;
    font-weight: 500;
    margin-top: 16px;

    .icon {
      height: 21px;
      position: relative;
      top: -2px;
      width: 21px;
    }
  }

  &-subtitle {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 4px;
    margin-top: 12px;

    .divider {
      border-right: 1px solid #e5e9f2;
      box-sizing: border-box;
      height: 12px;
      width: 1px;
    }
  }

  .table-container {
    border-bottom: 1px solid #e5e9f2;
    border-top: 1px solid #e5e9f2;
    overflow: visible;
  }

  div {
    max-height: 100%;
  }

  table {
    table-layout: fixed;

    td {
      border: none !important;
      font-size: 0.9rem !important;
      padding: 0.1rem 0 !important;
    }

    tr {
      height: 24px;
    }

    th {
      border: none !important;
      padding: 0.1rem 0 !important;
      font-weight: normal;
      font-size: 0.9rem !important;
      color: #919893 !important;
    }
  }

  .cost-column {
    width: 30%;
  }

  .quantity-column {
    width: 40px;
  }

  .help-container {
    right: 2px;
    top: 2px;
  }

  .value-column {
    padding-left: 0;
    width: 40%;

    span {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
