.user-list-table {
  .button {
    &.is-success {
      font-size: 16px;
      min-width: 130px;
      border-radius: 4px;
      padding: 8px;
    }
  }
  table {
    thead {
      td {
        div {
          color: #122048;
          font-family: Avenir;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 19px;
        }
      }
    }
  }
  tbody {
    td {
      span {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 19px;
      }
    }
  }
}
