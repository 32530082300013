@charset "UTF-8";
.view-port .is-fullheight {
  height: 94%; }

.inspection-list-container {
  height: calc(100vh - 150px);
  border-radius: 3px; }
  .inspection-list-container .turnboard-virtualized-list:focus-visible {
    outline: none !important; }
  .inspection-list-container .turnboard-header-wrapper {
    padding: 16px;
    display: flex;
    width: 100%;
    align-items: center; }
    .inspection-list-container .turnboard-header-wrapper h2 {
      margin: 0px;
      color: #122048;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.36px;
      line-height: 22px; }
    .inspection-list-container .turnboard-header-wrapper > button {
      text-transform: capitalize;
      margin-left: 10px;
      border: 1px solid #e5e9f2;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #122048;
      padding: 9px; }
      .inspection-list-container .turnboard-header-wrapper > button img {
        margin-right: 8px; }
    .inspection-list-container .turnboard-header-wrapper .custom-icon {
      width: 20px;
      height: 20px; }
    .inspection-list-container .turnboard-header-wrapper .dropdown-action-button {
      margin-left: 10px;
      cursor: pointer;
      position: relative; }
      .inspection-list-container .turnboard-header-wrapper .dropdown-action-button .dropdown .dropdown-trigger button {
        padding: 0px !important;
        border: 0px !important; }
      .inspection-list-container .turnboard-header-wrapper .dropdown-action-button .dropdown .dropdown-menu a {
        color: #122048;
        font-size: 12px;
        padding: 9px 16px !important; }
  .inspection-list-container .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff; }
    .inspection-list-container .data-table-header .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .inspection-list-container .data-table-header .action-button button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px; }
      .inspection-list-container .data-table-header .action-button .is-success:hover {
        background-color: #86c453 !important; }
      .inspection-list-container .data-table-header .action-button .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0; }
        .inspection-list-container .data-table-header .action-button .export img {
          margin-right: 8px;
          margin-top: -2px; }
      .inspection-list-container .data-table-header .action-button .inspection-action-buttons button {
        margin-right: 12px; }
      .inspection-list-container .data-table-header .action-button .inspection-action-buttons .is-primary-outlined {
        color: #3db3e2;
        border: 1px solid #3db3e2; }
    .inspection-list-container .data-table-header .more-action-button {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 10px; }
      .inspection-list-container .data-table-header .more-action-button .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        cursor: pointer;
        border: 0; }
        .inspection-list-container .data-table-header .more-action-button .export img {
          margin-right: 8px;
          margin-top: -2px; }
  .inspection-list-container .applied-filters-wrapper {
    display: flex;
    padding: 15px 20px; }
  .inspection-list-container .scroll-wrapper {
    width: 100%;
    height: 100%;
    overflow: scroll; }
  .inspection-list-container .turnboard-table-wrapper {
    width: 100%;
    height: calc(100vh - 215px);
    display: flex;
    overflow: scroll;
    position: relative; }
    .inspection-list-container .turnboard-table-wrapper::-webkit-scrollbar {
      width: 7px;
      background-color: transparent;
      height: 7px;
      position: absolute;
      right: 0; }
    .inspection-list-container .turnboard-table-wrapper::-webkit-scrollbar-track {
      box-shadow: transparent;
      background-color: transparent; }
    .inspection-list-container .turnboard-table-wrapper::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 7px;
      outline: none; }
    .inspection-list-container .turnboard-table-wrapper .is-pointer {
      cursor: pointer; }
    .inspection-list-container .turnboard-table-wrapper .custom-checkbox {
      width: 12px;
      margin: 0; }
    .inspection-list-container .turnboard-table-wrapper .checkbox {
      margin: 0; }
    .inspection-list-container .turnboard-table-wrapper .checkbox-inner {
      z-index: 0;
      width: 12px;
      height: 12px;
      border: 1px solid #122048;
      border-radius: 0; }
      .inspection-list-container .turnboard-table-wrapper .checkbox-inner:after {
        height: 6px;
        width: 2px;
        left: 4px;
        top: 1px; }
    .inspection-list-container .turnboard-table-wrapper .checkbox-label {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px; }
    .inspection-list-container .turnboard-table-wrapper .is-icon {
      height: 14px;
      width: 14px; }
    .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column; }
      .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title {
        color: #ffffff;
        width: 100%;
        text-align: center;
        padding: 15px 0px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 16px;
        position: relative; }
        .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title span {
          position: absolute;
          right: 20px;
          top: 15px;
          cursor: pointer; }
        .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title.collapsed {
          min-width: 20px;
          text-align: center; }
          .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .turnboard-item-title.collapsed span {
            position: relative;
            top: 0px;
            right: 0px; }
      .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .first-table .rdt_TableHeadRow {
        padding-left: 0; }
      .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper {
        border: 0px !important; }
        .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_TableCol,
        .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_TableCell {
          min-width: 50px;
          text-align: center;
          padding: 0px 10px; }
        .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_TableCol {
          padding: 10px; }
        .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper .data-table-wrapper .data-table-body .rdt_Table {
          border-color: #122048 !important;
          border-left: 0px !important; }
      .inspection-list-container .turnboard-table-wrapper .turnboard-item-wrapper.collapsed .data-table-container {
        border-right: 1px solid #122048;
        height: 100%; }
  .inspection-list-container .btn-save {
    width: 127px;
    height: 38px;
    font-size: medium; }

.inspection-table {
  overflow: auto;
  width: -webkit-fill-available; }
  .inspection-table::-webkit-scrollbar {
    height: 6px;
    width: 6px; }
  .inspection-table::-webkit-scrollbar-track {
    background: transparent; }
  .inspection-table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .inspection-table::-webkit-scrollbar-thumb:hover {
    background: #555; }
  .inspection-table .inner-header .tr:first-child {
    display: none !important; }
  .inspection-table .tr:first-child .th {
    padding: 0px !important; }
  .inspection-table .tr .th {
    padding: 10px !important; }
  .inspection-table .tr:hover .td:last-child .menu-item {
    display: block !important; }
  .inspection-table .th {
    color: #122048;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px; }
    .inspection-table .th .table-header {
      display: flex;
      align-items: center; }
    .inspection-table .th select {
      margin-top: 8px !important;
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e9f2;
      margin: 0px auto;
      outline: none;
      font-size: 12px;
      height: 23px; }
    .inspection-table .th .header-item {
      text-align: center;
      padding: 15px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
      color: #ffffff !important;
      position: relative; }
      .inspection-table .th .header-item span {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer; }
      .inspection-table .th .header-item.collapsed {
        min-width: 20px;
        text-align: center; }
        .inspection-table .th .header-item.collapsed span {
          right: 0px;
          top: 0px;
          position: relative; }
  .inspection-table .td {
    font-size: 12px; }
    .inspection-table .td:first-child {
      padding: 3px !important; }
    .inspection-table .td:last-child .menu-item {
      display: none !important; }
    .inspection-table .td .cell-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 15px; }
    .inspection-table .td .menu-item {
      width: 100%;
      height: 100%;
      padding: 10px;
      font-size: 14px;
      line-height: 15px; }
    .inspection-table .td .tags-container {
      text-align: -webkit-center !important; }
      .inspection-table .td .tags-container .pill {
        border-radius: 10px;
        height: 17px;
        padding: 3px 6px;
        margin-bottom: 0px;
        width: 65px; }
        .inspection-table .td .tags-container .pill span {
          color: #ffffff;
          font-size: 8px;
          line-height: 11px;
          cursor: pointer;
          font-weight: 700;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block; }
      .inspection-table .td .tags-container .p-absolute {
        position: absolute;
        top: 11px;
        left: 7px; }
        .inspection-table .td .tags-container .p-absolute:first-child {
          z-index: 4; }
        .inspection-table .td .tags-container .p-absolute:nth-child(2) {
          z-index: 3;
          left: 11px;
          top: 8px; }
        .inspection-table .td .tags-container .p-absolute:nth-child(3) {
          z-index: 2;
          left: 13px;
          top: 5px; }
    .inspection-table .td .link {
      color: #3db3e2;
      text-decoration-line: underline;
      line-height: 15px; }
    .inspection-table .td .note-icon img {
      width: 20px;
      height: 20px;
      margin-top: -2px; }
  .inspection-table .full-header {
    width: 1044px !important;
    display: flex !important; }
    @media screen and (min-width: 1230px) {
      .inspection-table .full-header {
        width: 100% !important; } }
  .inspection-table .full-body {
    width: 1044px !important;
    overflow: overlay !important; }
    .inspection-table .full-body::-webkit-scrollbar {
      width: 6px; }
    .inspection-table .full-body::-webkit-scrollbar-track {
      background: transparent; }
    .inspection-table .full-body::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px; }
    .inspection-table .full-body::-webkit-scrollbar-thumb:hover {
      background: #555; }
    @media screen and (min-width: 1230px) {
      .inspection-table .full-body {
        width: 100% !important;
        overflow: overlay !important; } }

.damage-setup .select {
  width: 100%;
  box-shadow: none !important;
  cursor: pointer !important; }

.damage-setup .select:not(.is-multiple) {
  height: 2.9em !important; }

.damage-setup .field-select select {
  box-sizing: border-box;
  height: 20px !important;
  cursor: pointer !important;
  border-radius: 3px;
  border: none !important;
  padding-top: 0px !important;
  outline: none;
  box-shadow: none;
  font-family: Avenir;
  box-shadow: none !important; }
  .damage-setup .field-select select::placeholder {
    color: #8e8e8e !important;
    opacity: 1; }
  .damage-setup .field-select select ::–webkit-input-placeholder {
    color: #8e8e8e !important;
    opacity: 1; }
  .damage-setup .field-select select ::after {
    top: 10px !important; }
  .damage-setup .field-select select:disabled {
    cursor: not-allowed !important; }
