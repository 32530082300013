.mobile-work-detail-note {
  padding: 16px;

  &-title {
    color: #3DB3E2;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  &-first {
    text-overflow: ellipsis; 
    overflow: hidden; 
    white-space: nowrap;
    padding-right: 16px;
  }

  &-arrow {
    align-items: flex-start;
  }

  &-arrow-image {
    transition-duration: 0.5s;
    transition-property: transform;
  }

  &-arrow-rotate {
    transform: rotate(180deg);
  }
}

.mobile-work-note {
  border-bottom: 1px solid #e5e9f2;
  padding-top: 8px;
  padding-bottom: 8px;

  &-first {
    padding-top: 0px;
  }

  &-last {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  &-unique {
    padding-bottom: 0px;
    border-bottom: 0px;
  }

  &-title {
    color: #0081c3;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    margin-bottom: 4px;
  }
}