$breakpoint-normal: 1380px;
$breakpoint-huge: 1650px;

.bottom-section:before {
  content: '';
  height: 1px;
  top: -0.5rem;
  left: 0.5rem;
  right: 1.5rem;
  background: #ecedee;
  position: absolute;
}

.bottom-section {
  position: absolute;
  bottom: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

.DayPicker-Weekday {
  font-size: 1vw;
  color: #3db3e2 !important;
  font-weight: 500 !important;
}

.DayPicker-Day {
  font-size: 12px;
  padding: 0.2rem;
}

.DayPicker-Day--today {
  color: #3db3e2 !important;
}

.day {
  width: 24px;
  height: 22px;
  margin: 0 !important;
  text-align: center;
  vertical-align: baseline;
  display: inline-block;
  padding-top: 1px;
}

.move-out {
  background-color: #122149;
  color: white;
  border-radius: 50%;
}

.move-in {
  background-color: #3db3e3;
  color: white;
  border-radius: 50%;
}

.transfers {
  border: 1px dashed #122149;
  border-radius: 50%;
}

@media (max-width: $breakpoint-normal) {
  .DayPicker-Day {
    font-size: 9.5px;
  }

  .day {
    width: 18px;
    height: 18px;
  }
}

@media (min-width: $breakpoint-huge) {
  .DayPicker-Weekday {
    font-size: 14px;
  }

  .DayPicker-Day {
    font-size: 14px;
  }

  .day {
    width: 24px;
    height: 24px;
  }
}
