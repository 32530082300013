$tablet: 769px;
$xsMobile: 620px;

.nav-bar {
  z-index: 10;
}
.main-text-filter {
  min-width: 230px;
  position: absolute;
  right: -8px;
  top: 45px;
  width: 100%;
  border-radius: 20px !important;
  border: 1px solid #dbdbdb;
  background: url(./icons/search@2x.svg) no-repeat scroll 14px 7px;
  background-color: white;
  min-height: 38px !important;
  max-height: 38px !important;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 7px;

  &.searching {
    height: 38px !important;
    background: none !important;
  }

  &.expanded {
    max-height: 205px !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  input {
    margin-left: 21px !important;
    border: none;
    background-color: inherit;
    width: calc(100% - 20px) !important;
  }
  input:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .results {
    margin-top: 2px;
    border-top: 1px solid #e5e9f2;
    padding-top: 3px;
  }

  .more-link {
    color: #3db3e2;
    text-decoration: underline;
  }

  @media screen and (min-width: $xsMobile) {
    top: 2px;
    width: calc((100% / 4) - 1.5rem);
  }
}

.selectable-result {
  &.larger {
    padding: 0.25rem 0.5rem;
  }
  &:hover {
    background-color: #e5e9f2;
  }
}

.main-text-filter:focus-within {
  border-color: #0081c3;
  box-shadow: 0 0 0 0.125em rgba(0, 129, 195, 0.25);
}

nav.breadcrumb {
  margin-top: 20px;
  font-size: 14px;
  letter-spacing: 0.5px;

  li {
    margin-right: 10px;
    text-transform: capitalize;
  }

  li::before {
    margin-right: 10px;
  }

  .breadcrumb.has-succeeds-separator li + li::before {
    content: '>';
  }

  .breadcrumb li:first-child a {
    padding: 0;
  }
}

.results-container {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 10px;
  padding-bottom: 14px;
}
