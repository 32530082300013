.tag-delete-wrapper {
    display: inline-block;
    margin-left: 0;
    span {
      img {
        margin-top: -10.3px;
        cursor: pointer;
      }
    }
  .tag-delete-dropdown {
    display: none;
    max-width: 140px;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px 0 #C1C5C9;
    position: absolute;
    margin-top: -8px;
    z-index: 100;
    width: 100%;
    padding: 8px;
    text-align: center;
    p {
      color: #122048;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 14px;
    }
  }
  .active {
    display: block;
  }
  .action-container {
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin-top: 3.5px;
    p {
      font-size: 8px;
      margin-right: 12px;
      color: #3DB3E2;
      line-height: 11px;
      text-decoration: underline;
      padding-left: 3px;
      cursor: pointer;
    }
    button {
      font-size: 8px;
      color: #FFFFFF;
      line-height: 11px;
      letter-spacing: normal;
      height: 20px;
      padding: 4.5px 8.5px;
      border-radius: 0;
    }
  }
}
