.template__details {
  &__card {
    background-color: rgba(248,249,250,0.25);
    border: 1px solid #E5E9F2;
    margin-top: 8px;
    padding: 8px 16px;

    &__child {
      border-bottom: 1px solid #E5E9F2;
      padding: 16px 0;
      
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .collapsible-toggler {
        cursor: pointer;
      }
    }

    &__subtitle {
      color: #0081C3;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 16px;
      margin-bottom: 8px;
    }

    &__title {
      color: #0081C3;
      font-size: 14px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  &__general {
    border-bottom: 1px solid #C1C5C9;
    padding-bottom: 8px;

    &__select {
      width: 30%;
      display: flex !important;
      align-items: center;
      margin-top: 5px;
      font-size: 12px;
    }

    &__text {
      color: #122048;
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0;
      vertical-align: middle;
    }
  }

  &__input {
    margin-left: 8px;
    vertical-align: middle;
    height: 25px !important;
    text-align: center;
    width: 38px !important;
  }

  &__container {
    .is-inline {
      vertical-align: middle;
    }

    .text-button {
      color: #122048;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px;
      min-width: 64px;
      padding-left: 0;
      padding-right: 0;

      &__label {
        margin-left: 12px;
        vertical-align: middle;
      }
  
      .caret {
        &.upside {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.tippy-tooltip {
  &.status-theme {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    background-color: #fff;
    color: #26323d;
    min-width: 312px;
    min-height: 207px;
    
    .tippy-content {
      width: 300px;
      height: 152px;
      box-sizing: border-box;
      padding: 30.5px 16.5px 24.5px 16.5px;
    }

    &[data-placement^='top'] > .tippy-arrow {
      border-top-color: #fff;
    }
    &[data-placement^='bottom'] > .tippy-arrow {
      border-bottom-color: #fff;
    }
    &[data-placement^='left'] > .tippy-arrow {
      border-left-color: #fff;
    }
    &[data-placement^='right'] > .tippy-arrow {
      border-right-color: #fff;
    }
  }
}
