.inspection-modal-wrapper {
  width: calc(100% - 275px);
  height: calc(100vh - 64px);
  position: fixed;
  top: 64px;
  right: 0;
  z-index: 100;
  background-color: #4c5674;
  .inspection-modal-header {
    width: 100%;
    background-color: #122048;
    padding: 22px 24px;
    color: #ffffff;
    .columns {
      &:last-child {
        span {
          width: 100%;
          display: inline-block;
          text-align: right;
        }
      }
      p {
        width: 100%;
        display: inline-block;
        text-align: right;
      }
    }

    h4,
    p {
      font-weight: 700;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.36px;
    }
    .action-button {
      cursor: pointer;
    }
    .action-button-close {
      width: 13.71px;
    }
  }
  .thumbnail-slider-container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .media-container {
    width: 216px !important;
    height: 360px;
    margin: 50px auto;
    img {
      height: 360px;
      width: 216px;
      border: 1px solid #122048;
    }
    video {
      height: 360px;
      width: 216px;
      border: 1px solid #122048;
    }
  }
  .media-thumbnail-container {
    cursor: pointer;
    margin-top: 16px;
    opacity: 0.5;
    border: 1px solid #122048;
    width: 41px !important;
    height: 68px !important;
    img {
      width: 41px;
      height: 68px;
    }
    video {
      width: 41px;
      height: 68px;
    }
  }
  .slick-current {
    .media-thumbnail-container {
      opacity: 1;
      border-color: #ffffff;
    }
  }
  .slick-prev,
  .slick-next {
    height: 40px;
    width: 40px;
  }
  .slick-prev {
    left: 33%;
    z-index: 9999;
    background-image: url('../../ui/icons/icons/slider-back-arrow.svg');
    &:before {
      content: '';
    }
  }
  .slick-next {
    right: 33%;
    background-image: url('../../ui/icons/icons/slider-forward-arrow.svg');
    &:before {
      content: '';
    }
  }
  .is-align-items-center {
    align-items: center;
  }
  .slick-cloned {
    visibility: hidden;
  }

  .download-all {
    font-weight: 600;
    cursor: pointer;
  }
}
