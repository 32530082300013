$grey-lighter: hsl(0, 0%, 93%);
$link: #3eb3e2;

div.pie-chart-card {
  box-shadow: none;
  border: 1px solid $grey-lighter;
  &.short {
    overflow: hidden;
    height: 150px;
    padding-top: 5px;
    padding-left: 10px;
  }
}
