$outline: #e5e9f2;
$desktop: 1024px;
$tablet: 769px;
$xsMobile: 500px;

.with-border {
  border: 1px solid $outline;
}

.table {
  tr {
    background: white;

    td {
      padding: 14px 0px;
      &:first-of-type {
        div {
          margin-left: 13.5px;
        }
      }
    }
  }
}

.table-header {
  padding: 24px 24px 4px 24px !important;
  position: relative;
  overflow: visible;
  z-index: 100;

  &.has-filters-expanded {
    padding-bottom: 0 !important;
  }

  .tab-options {
    background: white;
    margin: auto;
    min-width: max-content;
    max-width: 600px;

    &-container {
      height: 35px;
      left: 0;
      max-width: 100%;
      padding: 0;
      position: absolute;
      top: -35px;
    }

    li {
      border: 1px solid #e5e9f2;
      border-radius: 5px 5px 0 0;
      color: #122048;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      font-weight: 300;
      margin-right: 2px;
      padding: 6px 16px;

      &.active {
        background: #0081c3;
        color: white;
        font-weight: 600;
      }
    }
  }

  .tool-section {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding-right: 5px;
  }

  .dropdown-menu {
    width: 100%;
  }

  .main-button {
    width: 100% !important;
  }

  &.no-table-top {
    border: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  @media screen and (min-width: $xsMobile) {
    .dropdown-menu {
      right: 0;
    }
  }

  @media screen and (min-width: $tablet) {
    .main-button {
      width: 127px !important;
    }
  }
}

.table-tools {
  margin-top: -40px;
}

.text-filter {
  border-radius: 20px !important;
  background: url(./icons/search@2x.svg) no-repeat scroll 7px 7px;
  padding-left: 35px !important;
  width: 190px !important;
  height: 38px !important;

  &::placeholder {
    color: #363636;
    opacity: 1; /* Firefox */
  }
}

.tags {
  .tag {
    cursor: pointer;
    color: #122048;
    background-color: rgba(139, 211, 237, 0.25);
    font-size: 12px;
  }
}

.menu-tooltip {
  position: absolute;
  border: 1px solid #c1c5c9;
  background-color: #fff;
  border-radius: 5px;
}

.focused {
  background-color: rgba(193, 197, 201, 0.3);
}

.table-cell {
  position: relative;
  &.action-item {
    vertical-align: middle;
  }
}

.pivot {
  width: 100%;
  // margin-left: -1px;
  display: table;
  grid-auto-rows: 100px;

  div.row {
    margin-left: 0px !important;
    background-color: #f8f9fa;
    border: 1px solid #e5e9f2;
    padding: 14px 0px 14px 85px;
    .div-column {
      display: table-cell;
      vertical-align: top;
      color: #122048;
      div {
        margin-left: 0px !important;
      }
    }
    &:last-child {
      border-bottom: 0px;
    }
  }

  .div-header {
    border: 1px solid #122048 !important;
    background-color: #122048 !important;
    padding: 14px 0px 14px 85px;
    margin-left: 0px !important;
    .div-column {
      border: 0px;
      padding: 0px;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff !important;
    }
  }
}

.pivot-container {
  padding: 0px !important;
  .pivot {
    margin-left: 0px !important;
  }
}

.tool-button {
  height: 38px !important;
  border: none !important;
  // padding: 1rem !important;
  &.selected {
    background-color: rgba(139, 211, 237, 0.25);
  }
}
.tool-button:hover {
  border: 1px !important;
  background-color: #eee;
}

.is-wide {
  width: 100% !important;
}

.toolbar-parent {
  justify-content: center;
  max-width: 100%;

  @media screen and (min-width: $desktop) {
    max-width: 60%;
  }
}

.toolbar {
  justify-content: flex-start;

  .divider {
    border-bottom: 1px solid $outline;
    height: 1px;
    width: 100%;
    padding: 0 !important;
  }
  .tool-section {
    .tool-button {
      padding-right: 1rem !important;
    }
    .tool-dropdown {
      padding-right: 1rem !important;
    }
  }

  ~ .pill-container {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-right: 5px;

    .small-text {
      max-width: 90px;
      min-width: 90px;
    }

    @media screen and (min-width: $desktop) {
      justify-content: flex-end;

      .small-text {
        max-width: 110px;
        min-width: 110px;
      }
    }
  }

  @media screen and (min-width: $tablet) {
    .divider {
      border-right: 1px solid $outline;
      height: 1.5rem;
      max-width: 1px;
      padding: 0 !important;
    }
  }

  @media screen and (min-width: $desktop) {
    justify-content: flex-end;
  }
}

.filter-button {
  width: 100% !important;
  justify-content: left !important;
  border: none !important;
  color: #122149 !important;
  box-shadow: none !important;

  &.is-primary {
    &:hover {
      color: #122149 !important;
      background-color: rgba(139, 211, 237, 1) !important;
    }
  }

  &.is-secondary {
    &:hover {
      color: #122149 !important;
      background-color: rgba(139, 211, 237, 1) !important;
    }
  }
}

.filter-dropdown {
  .dropdown-content {
    min-width: 205px;
    padding: 0 !important;

    @media screen and (max-width: 1023px) {
      left: 0;
    }
  }
}

.options-button {
  border: none !important;
  background-color: transparent !important;
  padding: 0.5rem 1rem !important;
  width: 100% !important;
  text-align: left !important;
  font-size: 14px !important;
  cursor: pointer !important;

  &-label {
    margin-left: 4px;
    vertical-align: middle;
  }
}

.options-button:hover {
  background-color: #eee !important;
}
