.has-divider {
  margin: 16px 0;
  position: relative;
  width: 100%;
  max-width: 200px;
  height: 0;
  border-bottom: 1px solid hsla(0,0%,56.5%,.3);
  display: block;
}

.has-divider-text {
  position: absolute;
  padding: 0 10px;
  color: #a0a0a0;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: #f8f9fa;
  text-align: center;
}