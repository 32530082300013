.mobile-tabs-container {
  background: #FFFFFF;
  border-top: 1px solid #ced4da !important;

  .tabs {
    padding: 5px 10px;
  
    li {
      padding: 4px 0;
  
      a {
        border: 0;
        padding: 0;
      }
    }
  
    .tab-icon {
      font-size: 22px;
      margin-bottom: 5px;
    }
  
    .tab-title {
      font-size: 10px;
    }
  }
}