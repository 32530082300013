.qc-header {
  display: flex;
  justify-content: center;
  align-items: center;

  &-title {
    display: flex;
    flex: 1;
    color: #ffffff;
    font-weight: 300;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 21px;
  }
  
  &-back {
    width: 48px;
    margin-left: 16px;
    display: flex;
    align-items: center;
  }

  &-actions {
    width: 48px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-actions-hidden {
    visibility: hidden;
  }
}
