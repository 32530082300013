.stepper {
  display: inline-block;
  left: 50%;
  position: relative;
  text-align: center;
  transform: translateX(-50%);

  &-container {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    text-align: center;
  }

  &-item {
    display: inline-block;
    margin: 10px 60px 10px 0;
    max-width: 61px;
    min-width: 100px;
    position: relative;
    text-align: center;

    &:first-of-type {
      .status-indicator {
        &::before {
          display: none;
        }
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    .label {
      color: #c1c5c9;
      font-size: 13.44px;
      font-weight: 300;
      line-height: 18px;
      margin-top: 7px;
    }

    .status-indicator {
      display: block;
      background-color: #ffffff;
      border: 1.3px solid #e5e9f2;
      border-radius: 50%;
      box-sizing: border-box;
      height: 37px;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      width: 37px;

      &:before {
        background: #e5e9f2;
        content: '';
        display: block;
        height: 1px;
        left: -85px;
        top: 17px;
        position: absolute;
        width: 85px;
      }

      .check-icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.active {
      .label {
        color: #8dc85c;
        font-weight: bold;
      }

      .status-indicator {
        border: 1.3px solid #8dc85c;
      }
    }

    &.done {
      .label {
        color: #8dc85c;
      }

      .status-indicator {
        background: #8dc85c;
        border: 1.3px solid #8dc85c;
      }
    }

    &.active,
    &.done {
      .status-indicator {
        &:before {
          background: #8dc85c;
        }
      }
    }
  }
}

.navigation-container {
  align-items: center;
  bottom: -30px;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
}
