.tab-options {
  background: white;
  margin: auto;
  min-width: max-content;
  max-width: 600px;

  &-container {
    height: 35px;
    left: 0;
    max-width: 100%;
    padding: 0;
    position: absolute;
    top: 0;
  }

  span {
    background-color: #F1576D;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -9px;
    &:hover {
      background-color: #F1576D !important;
      color: #F1576D !important;
    }
  }

  li {
    border: 1px solid #e5e9f2;
    border-radius: 5px 5px 0 0;
    color: #122048;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    margin-right: 2px;
    padding: 6px 16px;

    &.active {
      background: #0081c3;
      color: white;
      font-weight: 600;
    }
  }
}
