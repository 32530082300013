html,
body {
  overflow: hidden;
}

strong {
  font-weight: 700 !important;
}

.fixed-items {
  left: 0;
  position: fixed;

  &.open {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    display: block;
    top: 64px;
    width: 100%;
  }

  .logo-container {
    background-color: white;
    border-bottom: 1px solid #000;
    border-right: 1px solid #d2d2d2;
    height: 64px;
    text-align: center;
    top: 0;

    .turnable-logo {
      padding-top: 12px;
      width: 166px;
    }
  }

  @media screen and (min-width: 768px) {
    top: 0;
    width: 0;

    &.open {
      width: 0;
    }
  }
}

.view-port {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: left 500ms;

  @media screen and (max-width: 1023px) {
    left: 0;
    width: 100%;
  }

  &.is-touch {
    left: 0;
    width: 100%;
  }

  &.is-main-site {
    top: 64px;
    height: calc(100% - 64px);
  }

  @media screen and (min-width: 768px) {
    &.has-open-sidebar {
      left: 275px;
      width: calc(100% - 275px);
    }
  }
}

.header {
  margin-bottom: 1.2rem;
}

button {
  &.main-button,
  &.is-success {
    font-size: 16px;
  }
}

$primary: #3cb3e2;
$third: #0081c3;
button.is-primary,
button.is-primary:active,
button.is-primary:hover,
button.is-primary:focus,
button.is-primary:disabled {
  background-color: $primary !important;
}

button.is-danger,
button.is-danger:active,
button.is-danger:hover,
button.is-danger:focus,
button.is-danger:disabled {
  background-color: #e93f33 !important;
}

button.is-secondary,
button.is-secondary:active,
button.is-secondary:hover,
button.is-secondary:focus,
button.is-secondary:disabled,
div.is-secondary,
div.is-secondary:active,
div.is-secondary:hover,
div.is-secondary:focus,
div.is-secondary:disabled {
  background-color: #fff;
  border: 1px solid $primary;
  color: $primary;
}

button.is-third,
button.is-third:active,
button.is-third:hover,
button.is-third:focus,
button.is-thirdy:disabled {
  // div.is-third,
  // div.is-third:active,
  // div.is-third:hover,
  // div.is-third:focus,
  // div.is-third:disabled
  background-color: $third;
  border: 1px solid $third;
  color: #fff;
}

.auth0-lock.auth0-lock
  .auth0-lock-social-button.auth0-lock-social-big-button
  .auth0-lock-social-button-text {
  color: #000;
}

/*[data-tooltip]:not(.is-disabled)::after,
[data-tooltip]:not(.is-loading)::after,
[data-tooltip]:not([disabled])::after {
  border-color: transparent transparent rgba(74, 74, 74, 0.2) transparent !important;
}*/

[data-tooltip]:not(.is-disabled)::before,
[data-tooltip]:not(.is-loading)::before,
[data-tooltip]:not([disabled])::before {
  background-color: #fff;
  border: 1px solid lightgrey;
  color: #0d264f;
}

.wrapping-container {
  flex-wrap: wrap;
}
[aria-disabled='false'] {
  cursor: unset !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

// Error Container
.error-container {
  background-color: #ffffff;
  border: 1px solid #e93f33;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px 16px;
  text-align: center;
  width: 100%;

  .error-description {
    color: #e93f33;
    font-weight: bold;
  }
}

// Caveat Container
.caveat-container {
  background-color: #FFFFFF;
  border: 1px solid #E5E9F2;
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px 16px;
  text-align: left;
  width: 100%;

  .caveat-description {
    font-size: 12px;
    font-style: oblique;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 14px;
  }
}

// help-container
.help-container {
  color: #122048;
  display: inline-block;
  font-weight: 300;
  height: 23px;
  position: absolute;
  text-align: center;
  text-transform: none;
  width: 23px;

  &:hover {
    .help-body {
      display: block;
    }
  }

  .help-body {
    background: white;
    border: 1px solid #e5e9f2;
    top: 33px;
    content: '';
    display: none;
    max-height: none;
    padding: 13px 16px;
    position: absolute;
    right: -31px;
    z-index: 1;

    &:hover {
      display: block;
    }

    &::before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid rgba(74, 74, 74, 0.9);
      content: '';
      height: 0;
      right: 31px;
      position: absolute;
      top: -10px;
      width: 0;
    }

    .title {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }

    .list {
      border: 0;
      box-shadow: none;
      margin-top: 8px;
      padding-left: 10px;

      .highlighted {
        .label,
        .value {
          color: #0081c3;
        }
      }

      .label {
        min-width: 130px;
      }

      .label,
      .value {
        display: inline-block;
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
