.is-align-items-center {
  align-items: center !important;
}

.is-flex-wrap-wrap {
  flex-wrap: wrap !important;
}

input[type='radio'] {
  display: block !important;
}

.notification {
  align-items: center;
  padding: 10px 15px !important;
  font-size: 12px;
  margin-top: 0px;
  button {
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    margin-left: auto;
  }
}

.floating-action-button {
  position: sticky;
  top: 15px;
}

.go-back-button {
  background-color: transparent;
  border: 0;
  color: #3db3e2;
  font-size: 14px;
  line-height: 19px;
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.inspection-main-card {
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  padding-right: 24px;
  .inspection-card-header {
    padding: 16px 0px 31px 16px;
    .inspection-heading {
      h2 {
        color: #3db3e2;
        font-size: 24px;
        font-weight: 900;
        line-height: 33px;
        margin-bottom: 7px;
      }
      p {
        color: #122048;
        font-size: 18px;
        font-weight: 900;
        letter-spacing: 0.36px;
        line-height: 25px;
      }
    }
    .inspector {
      margin-left: auto;
      text-align: right;
      .inspection-date {
        padding: 8.5px 16px;
        background-color: #122048;
        border-radius: 17.5px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        display: inline-block;
        margin-bottom: 8px;
        text-align: center;
      }
      .is-approved {
        background-color: #8dc85c;
      }
      p {
        color: #122048;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-align: right;
        margin-bottom: 4px;
      }
    }
  }
}

.inspection-action-card {
  button {
    max-width: 127px;
    width: 100%;
    font-size: 16px !important;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
    margin-bottom: 8px;
  }
  .is-outlined {
    border-radius: 4px;
    border-color: #3db3e2;
    color: #3db3e2;
    &:hover {
      border-color: #3db3e2;
      color: #3db3e2;
    }
  }
  .pdf-button {
    border: 0;
    background-color: transparent;
    color: #3db3e2;
    line-height: 19px;
    font-weight: 700;
    font-size: 14px !important;
    display: block;
    text-align: left;
    outline: 0;
    cursor: pointer;
    margin-bottom: 10px;
    max-width: fit-content;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

.child-accordion-data {
  margin-top: 8px;
  .m-b-8 {
    margin-bottom: 8px;
  }
  h4 {
    color: #122048;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    margin-right: 2px;
  }
  .is-link {
    color: #3db3e2;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    outline: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  h3 {
    color: #122048;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 0.27px;
    line-height: 16px;
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .state-heading {
    width: 100%;
    max-width: 182px;
    margin-right: 10px;
  }
  .state-description {
    width: 100%;
  }
  .button {
    max-width: 162px;
    width: 100%;
    border: 1px solid #e5e9f2;
    border-radius: 21.5px;
    background-color: #ffffff;
    color: #122048;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.22px;
    line-height: 15px;
    text-align: center;
    padding: 12px 15px;
    height: 32px;
    margin-right: 8px;
    margin-bottom: 5px;
    cursor: pointer;
  }
  .activeButton {
    background-color: #f1576d;
    border-color: #f1576d;
    color: #ffffff;
    font-weight: 900;
    &:hover,
    &:focus {
      color: #ffffff;
    }
  }
  textarea {
    border: 1px solid #e5e9f2;
    border-radius: 3px;
    background-color: #ffffff;
    resize: none;
    height: 81px;
    width: 100%;
    max-width: 318px;
    color: #122048;
    font-size: 11px;
    line-height: 15px;
    padding: 6px 6px;
    &:focus-visible {
      outline: none;
    }
  }
  .comment-area {
    h3 {
      max-width: none;
    }
  }
  .inspection-thumbnail-inner {
    height: 65px;
    width: 52px;
    border: 1px solid #122048;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 15px;
    margin-bottom: 5px;
    overflow-y: clip;
    img {
      width: 100%;
    }
    button {
      position: absolute;
      top: 0;
      right: -8px;
      padding: 0px;
      border: 0px;
      background-color: transparent;
      height: 14px;
      width: 14px;
      cursor: pointer;
      img {
        vertical-align: top;
      }
    }
  }
}

.child-accordion-need-work {
  .state-heading {
    max-width: 140px;
    width: 100%;
    margin-right: 16px;
    p {
      margin-top: -5px !important;
      color: #91989e;
      font-size: 10px;
      font-weight: 300;
      line-height: 14px;
    }
  }
  .state-description {
    width: 100%;
    .solution-button {
      width: 100%;
      max-width: 162px;
      margin-right: 8px;
      margin-bottom: 8px;
      input[type='checkbox'] {
        display: none;
      }
      .solution-button-checked {
        background-color: rgba(241, 87, 109, 0.15);
        border-color: #f1576d;
        font-weight: 900;
        letter-spacing: 0.22px;
        color: #f1576d;
        display: flex;
        align-items: center;
        img {
          margin-right: 6px;
          width: 15px;
        }
      }
    }
  }
}

.pdf-link {
  font-weight: 700;
  color: #3db3e2;
  text-decoration-line: underline;
  line-height: 19px;
}

.custom-text-responsive {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
