@font-face {
  font-family: 'icomoon';
  src: url('./fonts/icomoon.eot?gt9yy7');
  src: url('./fonts/icomoon.eot?gt9yy7#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?gt9yy7') format('truetype'),
    url('./fonts/icomoon.woff?gt9yy7') format('woff'),
    url('./fonts/icomoon.svg?gt9yy7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='turnable-icon-'],
[class*=' turnable-icon-'] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.turnable-icon-cleaning:before {
  content: '\e900';
}
.turnable-icon-carpetclean:before {
  content: '\e901';
}
.turnable-icon-carpetreplace:before {
  content: '\e902';
}
.turnable-icon-concretevinylreplace:before {
  content: '\e903';
}
.turnable-icon-paint:before {
  content: '\e904';
}
.turnable-icon-punch:before {
  content: '\e905';
}
.turnable-icon-sparkle:before {
  content: '\e906';
}
