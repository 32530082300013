
.toggle-control {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    input:checked ~ .control {
        background-color: #72B8D7;
        &:after {
            left: 20px;
        }
    }
    .control {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 40px;
        border-radius: 25px;
        background-color: #D0D1D4;
        transition: background-color 0.15s ease-in;
        &:after {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            width: 20px;
            height: 20px;
            border-radius: 25px;
            background: white;
            transition: left 0.15s ease-in;
        }
    }
  }
  