// single select input field container
.css-1s2u09g-control {
  border-color: #8d8f94 !important;
  min-height: 45px !important;
  border-radius: 3px;
}


// multiple selection input field container
.css-1rhbuit-multiValue {
  border-radius: 3px !important;
  background-color: #f8f9fa !important;
}
.css-12jo7m5 {
  line-height: 19px;
  padding: 7px 8px !important;
}
.css-xb97g8:hover {
  background-color: transparent !important;
}
// Selected option or tag style
.css-g1d714-ValueContainer {
  min-height: 45px;
  max-height: 100px;
  &:focus,
  &:hover {
    border-color: #3db3e2;
  }
  .css-1rhbuit-multiValue {
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa;
    font-size: 14px;
    line-height: 19px;
    color: #122048;
    svg {
      fill: #122048;
    }
  }
}
