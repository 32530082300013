.info-section {
  .section-body {
    align-items: center;
    display: flex;
    justify-content: space-around;

    &.open-sidebar {
      @media screen and (min-width: 1216px) and (max-width: 1400px) {
        flex-direction: column;

        .item {
          &:last-of-type {
            padding-top: 0;
          }
          .has-extra-info {
            .extra-info {
              left: -30px;

              &::before {
                left: 25px;
                top: -8px;
              }
            }
          }
        }
      }
    }
  }

  .section-header {
    background: #0081c3;
    color: #ffffff;
    font-size: 12px;
    font-weight: 900;
    padding-bottom: 4px;
    padding-top: 4px;
    text-align: center;
  }

  .item {
    font-size: 12px;
    padding: 14px 0;

    .labels {
      line-height: 16px;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-value {
      display: inline-block;
    }

    &-label {
      display: inline-block;
      min-width: 80px;
    }

    .dimmed {
      color: #91989e;
    }

    .has-extra-info {
      position: relative;

      &:hover {
        .extra-info {
          display: block;
        }
      }

      .extra-info {
        background-color: #ffffff;
        border: 1px solid #e5e9f2;
        display: none;
        left: -20px;
        padding: 14px;
        position: absolute;
        top: 25px;
        width: max-content;
        z-index: 1;

        &::before {
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid rgba(74, 74, 74, 0.9);
          content: '';
          display: block;
          height: 0;
          left: 25px;
          position: absolute;
          top: -8px;
          width: 0;
        }

        &-title {
          display: block;
          color: #021d49;
          font-weight: 500;
          margin-bottom: 8px;
        }

        &-item {
          align-items: center;
          color: #021d49;
          display: flex;
          justify-content: space-between;

          &-label {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
