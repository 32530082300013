.is-align-items-center {
  align-items: center !important;
}

.is-pointer {
  cursor: pointer;
}

.turnboard-all-notes-modal {
  font-family: Avenir;
  color: #122048;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  p {
    &.modal-sync-title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
    }
  }
  .modal-card {
    overflow: initial !important;
    .modal-card-body {
      overflow: initial !important;
      padding: 24px 24px;
      h4 {
        color: #122048;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.36px;
        line-height: 22px;
      }
      .is-desktop {
        margin-bottom: 0px;
      }
      .notes-export-section {
        margin-bottom: 16px;
        button {
          cursor: pointer;
          color: #122048;
          font-size: 14px;
          font-weight: 300;
          line-height: 19px;
          padding: 0px;
          margin-right: 16px;
          background-color: transparent;
          border: 0;
          img {
            margin-right: 8px;
            width: 20px;
          }
        }
        .export-icon {
          img {
            width: 18px;
          }
        }
      }
      // Applying styles to custom class from data-table for wrapping
      .sc-hKMtZM div:first-child {
        white-space: pre-wrap;
      }
      .action-button {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        cursor: pointer;
      }
      .notification {
        align-items: center;
        padding: 10px 15px !important;
        font-size: 12px;
        margin-top: 0px;
        button {
          background-color: transparent;
          border: none;
          padding: 0px;
          cursor: pointer;
          margin-left: auto;
        }
      }
    }
  }
}

.turnboard-manage-note-modal {
  label {
    color: #122048;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
  }
  .custom-label {
    font-weight: 900;
  }
  .general-error {
    font-weight: 600;
    font-size: 12px;
    color: #e93f33 !important;
  }
  .vendor-error {
    display: block;
    margin-right: 10px;
  }
  .select {
    width: 182px;
    select {
      width: 100%;
    }
  }
  .service-select {
    width: 200px;
  }
  .custom-column {
    padding-bottom: 0;
    margin-bottom: 6px !important;
  }
  .m-b-8 {
    margin-bottom: 8px;
  }
  textarea {
    resize: none;
    height: 73px !important;
  }
  .m-b-40 {
    margin-bottom: 40px;
  }
}

.turnboard-single-notes-modal {
  .no-notes {
    font-weight: 700;
    font-size: 14px;
    color: #e93f33;
  }
  .modal-sync-title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  h4 {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  .notes-container {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .note-text {
    margin-top: 8px;
  }
}

.note-table {
  max-height: 300px;
  font-size: 10px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  tr {
    &:first-child {
      th {
        padding: 0px !important;
      }
    }

    th {
      padding: 6px !important;
    }
  }
  th {
    color: rgb(18, 32, 72);
    background-color: #ced4da;
    font-family: Avenir;
    font-size: 10px;
    font-weight: 700;
    input {
      width: 100%;
      background-color: #ffffff;
      margin-top: 3px;
      outline: none;
    }
  }
  td {
    font-size: 10px;
    .cell-item {
      font-size: 10px;
      width: 100%;
      height: 100%;
      padding: 6px;
      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
      //text-align: center;
    }
    .note-icon {
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.character-limit {
  color: #91989e;
  font-size: 10px;
  span {
    font-weight: 700;
  }
}
