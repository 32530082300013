.add-note {
  color: #122048;
}

.edit-note-container {
  border-bottom: 1px solid #e5e9f2;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 300;
}

.note-title {
  color: #0081c3;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
}

.note-item {
  border-bottom: 1px solid #e5e9f2;

  .note-text {
    display: block;
    margin-bottom: 4px;
    max-width: 90%;
  }
}
