// Custom Checkbox
.checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  user-select: none;
  margin-right: 30px;

  &-input {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;

    &.is-active .checkbox-inner {
      background-color: #132048;
      border-color: #132048;

      &::after {
        transform: rotate(45deg) scaleY(1);
      }
    }
  }

  &-original {
    opacity: 0;
    outline: none;
    position: absolute;
    margin: 0;
    width: 0;
    height: 0;
    z-index: -1;
  }

  &-inner {
    display: inline-block;
    position: relative;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #fff;
    z-index: 1;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
      background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);

    &:after {
      box-sizing: content-box;
      content: '';
      border: 1px solid #fff;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 4px;
      position: absolute;
      top: 1px;
      transform: rotate(45deg) scaleY(0);
      width: 3px;
      transition: transform 0.15s ease-in 0.05s;
      transform-origin: center;
    }
  }

  &-label {
    display: inline-block;
    padding-left: 10px;
    line-height: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #606266;

    &.is-active {
      font-weight: 500;
      color: #132048;
    }
  }
}

// Custom Radio
input[type="radio"] {
  display: none !important;

  &+.custom-radio {
    background: white;
    border: 1px solid #122048;
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    left: 0;
    margin-right: 4px;
    position: relative;
    top: 0;
    vertical-align: middle;
    width: 12px;

    &:after {
      background: white;
      border-radius: 50%;
      content: '';
      display: none;
      left: 50%;
      height: 4px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4px;
    }
  }

  &:checked {
    &+.custom-radio {
      background-color: #122048;

      &:after {
        display: block;
      }
    }
  }
  
  &:disabled {
    &+.custom-radio {
      background-color: rgba(248, 249, 250, 0.25);
      border-color: gray;
    }

    &~span {
      color: gray;
    }
  }
}

// Select - Color Indicator
.select {
  &__color-indicator {
    background-color: #E0E66B;
    border: 1px solid #E0E66B;
    border-radius: 50px;
    box-sizing: border-box;
    display: inline-block;
    height: 7px;
    width: 7px;
    
    &+select {
      border: 0 !important;
      margin-left: 3px;
      vertical-align: middle !important;
    }
  }

  select {
    font-size: inherit !important;
  }

  &:after {
    border-color: #122048 !important;
  }
}

// form elements
.DayPickerInput {
  input {
    background-color: white;
    border: 1px solid #e5e9f2;
    border-radius: 4px;
    box-shadow: none;
    color: #363636;
    padding: 6px 9.5px;
  }
}

.input-label {
  display: block;
  margin-bottom: 7.5px;
}
