.elements-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  div {
    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.element-container {
  background-color: #FFFFFF;
  border: 1px solid #122048;
  border-radius: 3px;
  box-sizing: border-box;
  max-width: 280px;
  width: 280px;
  padding: 8px;
  text-align: left;
  
  &--preview {
    border-radius: 3px;
    // margin-bottom: 10px;
    margin-right: 10px;
    max-width: 280px;
    width: 100%;
  }
}

.search-element {
  border-radius: 3px;
  max-width: 280px;
  width: 280px;
}

.underline { 
  background-color: #122048 !important;
  height: 1px !important;
  max-width: 280px;
  width: 280px;
  border: revert !important
}
