.propertyFormManage {
  &__input-fields {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 47%;
    }
  }
}
