.keys-modal {
  .button-fixed-width {
    width: 130px;
  }
  .x-icon {
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 5px;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 0;
    left: -38px;
    background: #fff;
    outline: 3px solid white;
  }
  .check-icon {
    height: 27px;
    width: 27px;
    margin-bottom: 5px;
    position: absolute;
    top: 0;
    left: -38px;
    background: #fff;
  }
  .key-returned {
    padding-left: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    cursor: pointer;
    &.active {
      color: #9dcf76;
      outline: 1px solid #9dcf76;
    }
  }
  .holdover {
    padding-left: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    cursor: pointer;
    &.active {
      color: #ea3f32;
      outline: 1px solid #ea3f32;
    }
  }
  .position-relative {
    position: relative;
  }
}
