.invoice-data {
  align-items: baseline;
  color: #122048;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__extra-info {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__item {
    margin-right: 16px;
    margin-top: 16px;
    min-width: 130px;
  }

  .address {
    line-height: 1.2rem !important;

    &__line {
      display: block;
    }
  }

  .strong {
    font-weight: 700 !important;
  }

  .date-column {
    max-width: 10rem !important;

    .date-string {
      display: inline-block;
      position: relative;

      &::after {
        background: url('../../ui/icons/icons/calendar.svg');
        content: '';
        display: block;
        height: 16px;
        position: absolute;
        right: -20px;
        top: 0;
        width: 16px;
      }
    }
  }

  &__title {
    color: #0081c3 !important;
    font-size: 1rem !important;
    font-weight: normal !important;
    border-width: 0 !important;
    margin-bottom: 19px;
  }

  .react-datepicker-wrapper {
    top: -4px;
    position: relative;
    max-width: 110px;
    width: 100%;

    .input {
      padding-left: 0;
      padding-top: 0;
    }
  }
  
  .vendor-number input {
    padding-left: 0;
    padding-top: 0;
    margin-top: -4px;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color:#000;
    }
  }
}

.invoice-data__amount-due{
  font-size: 40px !important;
  color: #122048
}