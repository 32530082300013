// .setup-staff-service-row {
//   height: 40px;
//   line-height: 40px;
//   padding: 0 53px;
//   border-top: 1px solid #eef1f6;
// }
// .setup-staff-add-button {
//   margin-top: -3px;
//   margin-right: 5px;
//   height: 20px;
//   width: 20px;
// }
// div.setup-staff-main {
//   div.stackable-box {
//     border: 2px solid #eef1f6;
//     border-radius: 0;
//     margin-bottom: 0 !important;

//     &:not(:first-child) {
//       border-top: 0;
//     }
//     &.height-40 {
//       min-height: 40px;
//       line-height: 40px;
//     }
//   }
// }

.setup-staff-list-container {
  height: calc(100vh - 200px);
  //background-color: #ffffff;
  border-radius: 3px;
  .data-table-header {
    width: 100%;
    padding: 24px;
    background-color: #ffffff;

    .action-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        line-height: 22px;
      }
      .is-success {
        &:hover {
          background-color: #86c453 !important;
        }
      }
      .export {
        color: #122048;
        font-size: 14px;
        font-weight: 300;
        line-height: 19px;
        background-color: transparent;
        border: 0;
        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
      .staff-action-buttons {
        button {
          margin-right: 12px;
        }
        .is-primary-outlined {
          color: #3db3e2;
          border: 1px solid #3db3e2;
        }
      }
    }
  }
}
