$color-success: #8fc662;
$color-danger: #a0191c;
$color-info: #1384c1;
$color-light: #fff;
$color-dark: #333;

@function set-text-color($color) {
  @if (lightness($color) > 50) {
    @return $color-dark;
  } @else {
    @return $color-light;
  }
}

@mixin set-background($color) {
  background-color: $color;
  color: set-text-color($color);
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: $color-dark;

  &-success {
    @include set-background($color-success);
  }
  &-danger {
    @include set-background($color-danger);
  }
  &-info {
    @include set-background($color-info);
  }
}

#alertbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 10px;
}

#alertbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
