.setup-vendor-manage {
  .grey-circle {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    margin: 0 auto;
    background-color: #e5e9f2;
    line-height: 88px;
  }
  .blue-circle {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    margin: 0 auto;
    background-color: #3db2e2;
    line-height: 88px;
  }
  .is-button-link {
    color: #0081c3;
    //font-size: 11px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    outline: 0;
    &:hover {
      color: #363636;
    }
  }
  .vendor-manage-toggle {
    .toggle-control {
      margin-bottom: 10px;
    }
  }
}
.htooltip {
  background-color: #f8f9fa;
  &:hover {
    background-color: #e5e9f2;
  }
}
