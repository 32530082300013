.role-button {
  width: 100%;
  height: 50px !important;
  padding: 15px !important;
  font-size: 16px !important;
  letter-spacing: 0.36px !important;
  color: #122048 !important;
  border-color: #3db3e2 !important;
  &.is-primary {
    color: #ffffff !important;
  }
}

.role-name {
  color: #3cb3e2;
}
