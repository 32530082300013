.status-bar {
  background: rgba(85, 194, 184, 0.25);
  color: #122048;
  padding: 7px 29px;
  border-radius: 3px;
  display: none;

  strong {
    font-weight: 700 !important;
  }

  &--show {
    display: block;
  }
}
