.setup-list-fields {
  color: #122048;

  &__description {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__title {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 25px;
    text-align: center;
  }
  
  &__warning {
    color: #E93F33;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 25px;
    margin-top: 10px;
    text-align: center;
  }
}
