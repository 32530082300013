.loaders-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: center;
    margin: 0 25px;
    min-width: 569px;
  }
}

.loader-item {
  margin: 0 20px;
  position: relative;

  &.placeholder {
    background: transparent;
    height: 121px;
    width: 110.47px;
  }

  @media screen and (min-width: 768px) {
    margin: 0 40px;
  }

  svg {
    transform: rotate(-90deg);
    stroke-dasharray: 251;
    stroke-dashoffset: 251;
    animation: offsettozero 2s linear forwards;
  }

  @keyframes offsettozero {
    to {
      stroke-dashoffset: 0;
    }
  }
}

.title {
  color: #122048;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 41px;

  .highlighted {
    color: #3db3e2;
    font-weight: bold;
  }
}