@charset "UTF-8";
.pricing-list-container {
  border: 2px solid #e5e9f2;
  background-color: #ffffff;
  border-radius: 3px; }
  .pricing-list-container .pricing-table-header {
    width: 100%;
    padding: 24px 24px 0px 24px; }
    .pricing-list-container .pricing-table-header .header {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .pricing-list-container .pricing-table-header .header h2 {
        font-size: 21px;
        line-height: 29px;
        color: #122048;
        margin: 0px;
        font-weight: 400; }
      .pricing-list-container .pricing-table-header .header .export {
        font-size: 14px; }
        .pricing-list-container .pricing-table-header .header .export:hover {
          border-color: #b5b5b5; }
        .pricing-list-container .pricing-table-header .header .export:focus {
          border-color: #b5b5b5; }
        .pricing-list-container .pricing-table-header .header .export:active {
          border-color: #b5b5b5; }
        .pricing-list-container .pricing-table-header .header .export img {
          margin-right: 8px;
          margin-top: -2px; }
  .pricing-list-container .pricing-tab-container {
    width: 100%;
    padding: 5px 24px 24px 24px;
    overflow-x: hidden; }
    .pricing-list-container .pricing-tab-container .tab-option-container {
      height: 35px;
      max-width: 100%;
      padding: 0;
      margin-bottom: 10px; }
      .pricing-list-container .pricing-tab-container .tab-option-container button {
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
        line-height: 22px;
        color: #122048;
        background-color: transparent !important;
        margin: 6px 18px 6px 0px;
        display: inline;
        text-transform: capitalize;
        font-size: 16px; }
        .pricing-list-container .pricing-tab-container .tab-option-container button:hover {
          color: #3db3e2; }
      .pricing-list-container .pricing-tab-container .tab-option-container .active {
        color: #3db3e2;
        font-weight: 700;
        border-bottom: 3px solid #3db3e2; }
      .pricing-list-container .pricing-tab-container .tab-option-container .tab-info {
        height: 22px;
        width: 22px;
        margin-top: -3px;
        margin-left: 3px; }
    .pricing-list-container .pricing-tab-container .tab-content-container {
      height: 500px;
      overflow: hidden;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #e5e9f2;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 #c1c5c9;
      padding: 25px; }
      .pricing-list-container .pricing-tab-container .tab-content-container::-webkit-scrollbar {
        height: 6px;
        width: 6px; }
      .pricing-list-container .pricing-tab-container .tab-content-container::-webkit-scrollbar-track {
        background: transparent; }
      .pricing-list-container .pricing-tab-container .tab-content-container::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px; }
      .pricing-list-container .pricing-tab-container .tab-content-container::-webkit-scrollbar-thumb:hover {
        background: #555; }
      .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .field-input input {
        width: 100%;
        box-sizing: border-box;
        height: 35px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        padding: 13px 10px;
        outline: none;
        box-shadow: none;
        font-family: Avenir; }
        .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .field-input input:hover {
          border-color: #b5b5b5; }
        .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .field-input input:focus {
          border-color: #b5b5b5; }
        .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .field-input input::placeholder {
          color: #8e8e8e !important;
          opacity: 1; }
        .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .field-input input ::–webkit-input-placeholder {
          color: #8e8e8e !important;
          opacity: 1; }
      .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .th {
        border: 0; }
        .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .th .pricing-tab-header {
          margin-bottom: 10px; }
      .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .close-button {
        cursor: pointer; }
      .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .add-row {
        display: inline-block;
        cursor: pointer; }
        .pricing-list-container .pricing-tab-container .tab-content-container .pricing-table .add-row span {
          font-size: 12px; }
      .pricing-list-container .pricing-tab-container .tab-content-container table thead tr td {
        padding: 4px 11px; }
      .pricing-list-container .pricing-tab-container .tab-content-container table thead tr th {
        font-size: 15px;
        margin-bottom: 15px; }
      .pricing-list-container .pricing-tab-container .tab-content-container table tbody tr {
        margin-bottom: 3px; }
  .pricing-list-container .price-input {
    margin-top: 4px !important; }
    .pricing-list-container .price-input:hover {
      border-color: #b5b5b5;
      box-shadow: none; }
    .pricing-list-container .price-input:focus {
      border-color: #b5b5b5;
      box-shadow: none; }
    .pricing-list-container .price-input:active {
      border-color: #b5b5b5;
      box-shadow: none; }
    .pricing-list-container .price-input::placeholder {
      color: #8e8e8e !important;
      opacity: 1; }
    .pricing-list-container .price-input ::–webkit-input-placeholder {
      color: #8e8e8e !important;
      opacity: 1; }
  .pricing-list-container .select-wrapper {
    margin-top: -5px;
    height: 25px !important;
    font-size: 11px !important; }
    .pricing-list-container .select-wrapper select:hover {
      border-color: #b5b5b5;
      box-shadow: none; }
    .pricing-list-container .select-wrapper select:focus {
      border-color: #b5b5b5;
      box-shadow: none; }
    .pricing-list-container .select-wrapper select:active {
      border-color: #b5b5b5;
      box-shadow: none; }
    .pricing-list-container .select-wrapper select::placeholder {
      color: #8e8e8e !important;
      opacity: 1; }
    .pricing-list-container .select-wrapper select ::–webkit-input-placeholder {
      color: #8e8e8e !important;
      opacity: 1; }
  .pricing-list-container .select-inner {
    height: 25px;
    font-size: 10px !important; }
  .pricing-list-container .error-container {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    margin-left: 10px !important; }

.pricing-quotation {
  font-family: Avenir; }
  .pricing-quotation .mt-30 {
    margin-top: 30px; }
  .pricing-quotation .pricing-top-header {
    margin-top: 6px; }
    .pricing-quotation .pricing-top-header .pricing-header-container {
      display: flex;
      justify-content: space-between; }
      .pricing-quotation .pricing-top-header .pricing-header-container h2 {
        font-size: 24px;
        line-height: 33px;
        color: #122048;
        font-weight: 700; }
