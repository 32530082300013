@charset "UTF-8";
.view-user .input-set {
  margin-bottom: 10px; }

.view-user .input-field input.form-control {
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  border-radius: 3px;
  border: 1px solid #e5e9f2;
  padding: 13px 10px;
  outline: none;
  box-shadow: none;
  font-family: Avenir; }
  .view-user .input-field input.form-control:hover {
    border-color: #b5b5b5; }
  .view-user .input-field input.form-control:focus {
    border-color: #b5b5b5; }

.view-user .input-field input::placeholder {
  color: #8e8e8e !important;
  opacity: 1; }

.view-user .input-field input ::–webkit-input-placeholder {
  color: #8e8e8e !important;
  opacity: 1; }

.view-user .input-field select {
  width: 100%;
  box-sizing: border-box;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #e5e9f2;
  font-size: 14px;
  padding: 10px 10px;
  outline: none; }
  .view-user .input-field select:focus {
    border-color: #3db3e2; }

.view-user .input-field .css-14el2xx-placeholder {
  font-size: 14px; }

.view-user .input-field .css-1s2u09g-control {
  border-color: #e5e9f2 !important; }

.view-user .input-field .react-datepicker__input-container input {
  font-size: 14px; }
  .view-user .input-field .react-datepicker__input-container input::placeholder {
    color: #122048 !important;
    opacity: 1; }

.view-user .input-field .unit-select {
  margin-bottom: 8px; }

.view-user .input-field .checkbox-inner {
  z-index: 0; }

.view-user .input-field .checkbox-label {
  color: #7a7a7a !important;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px; }

.view-user .required-star {
  color: red; }

.view-user .password-label {
  padding-left: 12px;
  font-weight: 600;
  vertical-align: text-bottom;
  cursor: pointer; }

.view-user .button-wrapper {
  display: flex;
  justify-content: center; }

.view-user .permission-container {
  max-height: 200px;
  overflow-y: scroll; }
  .view-user .permission-container::-webkit-scrollbar {
    width: 6px; }
  .view-user .permission-container::-webkit-scrollbar-track {
    background: transparent; }
  .view-user .permission-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .view-user .permission-container::-webkit-scrollbar-thumb:hover {
    background: #555; }

.view-user .permission-set-helper-container {
  display: flex;
  justify-content: space-between; }

.view-user .permission-label {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0 !important;
  font-weight: bold; }

.view-user .permission-label-text {
  font-size: 13px !important; }

.view-user .permission-bg {
  background-color: #f8f9fa; }

.view-user .permission-set {
  padding: 10px; }

.view-user .permission-field-set {
  padding-top: 5px;
  display: flex;
  justify-content: space-between; }
  .view-user .permission-field-set .permission-field {
    width: 32%; }

.view-user .link {
  color: #3db3e2;
  text-decoration-line: underline;
  line-height: 15px;
  cursor: pointer; }

.view-user .label {
  font-weight: normal !important;
  font-size: 14px; }

.view-user .password-error {
  padding-left: 13px; }

.view-user .error-button {
  margin-bottom: auto; }

.view-user .mb-0 {
  margin-bottom: 0px !important; }

.view-user .user-permission-container {
  padding: 10px 12px 0px 12px;
  margin-bottom: 8px !important;
  border: 1px solid #e5e9f2; }

.view-user .label-heading {
  font-weight: 600; }

.view-user .error-top-spacing {
  margin-top: -9px !important; }

.view-user .permission-delete {
  cursor: pointer;
  margin-right: -8px !important; }

.view-user .button-disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important; }

.view-user .m-0 {
  margin: 0px !important; }

.view-user .p-0 {
  padding: 0px !important; }

.view-user .pb-0 {
  padding-bottom: 0px !important; }

.view-user .pt-7 {
  padding-top: 7px !important; }

.view-user .mb-8 {
  margin-bottom: 8px !important; }

.view-user .mt-8 {
  margin-top: 8px !important; }

.view-user .mt-n5 {
  margin-top: -5px !important; }

.view-user .ml-8 {
  margin-left: 8px !important; }

.view-user .ml-17 {
  margin-left: 17px !important; }

.view-user .mt-n20 {
  margin-top: -20px !important; }

.view-user .toggle-container {
  display: flex; }
  .view-user .toggle-container .toggle-control input:checked ~ .control {
    background-color: #3cb3e2; }
    .view-user .toggle-container .toggle-control input:checked ~ .control:after {
      left: 25px; }
  .view-user .toggle-container .toggle-control .control {
    width: 50px !important;
    height: 25px !important; }
  .view-user .toggle-container .toggle-control .control:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: white;
    transition: left 0.15s ease-in; }
  .view-user .toggle-container .block-text {
    margin-left: 0px;
    margin-right: 15px;
    font-style: normal;
    font-family: Avenir;
    color: #122048; }
  .view-user .toggle-container .active-text {
    margin-left: 15px;
    font-style: normal;
    font-family: Avenir;
    color: #122048; }

.view-user .secondary-card-space {
  margin-top: 10px;
  margin-bottom: -2px !important;
  margin-left: 5px;
  margin-right: 5px; }

.view-user .user-account-heading {
  font-size: 20px;
  font-family: Avenir;
  color: #122048;
  font-weight: bold; }

.view-user .user-pass-desc {
  font-size: 12px;
  font-family: Avenir;
  color: #122048; }

.view-user .user-pass-container {
  display: flex;
  align-items: center; }
  .view-user .user-pass-container .user-generate-container {
    display: flex;
    align-items: center; }

.view-user .pass-label {
  margin-top: 7px !important;
  font-weight: normal !important; }

.view-user .password-button-wrapper {
  display: flex;
  justify-content: end; }

.view-user .password-action-buttons {
  margin: 0;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border: none; }

.view-user .permission-rule-container {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden; }
  .view-user .permission-rule-container::-webkit-scrollbar {
    width: 6px; }
  .view-user .permission-rule-container::-webkit-scrollbar-track {
    background: transparent; }
  .view-user .permission-rule-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .view-user .permission-rule-container::-webkit-scrollbar-thumb:hover {
    background: #555; }

.view-user .note-container {
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 2px; }
  .view-user .note-container::-webkit-scrollbar {
    width: 6px; }
  .view-user .note-container::-webkit-scrollbar-track {
    background: transparent; }
  .view-user .note-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px; }
  .view-user .note-container::-webkit-scrollbar-thumb:hover {
    background: #555; }

.view-user label {
  font-size: 14px; }

.view-user .pill {
  font-size: 12px;
  padding: 0.4em 0.8em;
  margin: 0.25em;
  border-radius: 1em;
  border: none;
  outline: none;
  background-color: #f8f9fa; }

.view-user .content-spacing {
  align-items: baseline; }

.view-user .button-disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important; }

.view-user .checkbox {
  margin-right: 0px; }

.view-user .password-seperator {
  margin-left: 8px;
  margin-right: 8px; }

.view-user .loading-icon {
  color: #3cb3e2 !important;
  margin-top: -4px; }
