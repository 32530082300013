$outline: #e5e9f2;
$tablet: 769px;

.divider {
  border-right: 1px solid $outline;
  height: 1.5rem;
  width: 1px;
  padding: 0 !important;
}

.card-container {
  &:hover {
    position: relative;
    z-index: 101 !important;
  }
}

// large tooltip

.sync-tooltip {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 30px;

  &::before {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(74, 74, 74, 0.9);
    content: '';
    display: none;
    height: 0;
    left: 5px;
    position: absolute;
    top: 30px;
    width: 0;
  }

  &::after {
    background: white;
    border: 1px solid #e5e9f2;
    bottom: -74px;
    color: #122048;
    content: attr(data-custom-tooltip);
    display: none;
    font-size: 14px;
    font-weight: 300;
    height: fit-content;
    line-height: 19px;
    min-width: 220px;
    max-width: 220px;
    padding: 13px 16px;
    position: absolute;
    right: -15px;
    top: 38px;
    z-index: 100;
  }

  &:hover {
    &::after,
    &::before {
      display: block;
    }
  }

  &.break-text {
    &::after {
      white-space: break-spaces;
    }
  }
}

.refresh-caveat {
  color: #e93f33;
  font-size: 12px;
  left: 10px;
  padding-right: 10px;
  position: absolute;
  top: -10px;

  &.small {
    display: block;
    left: 0;
    margin-top: 14px;
    position: relative;
    text-align: center;
  }

  @media screen and (min-width: $tablet) {
    left: 151px;
    width: 485px;
  }
}
