.menu#sidebar {
  position: fixed;
  top: 64px;
  overflow: auto;
  padding-bottom: 64px;

  .collapser {
    padding-left: 24px;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .option {
    margin-left: 36px;
  }

  .down-arrow {
    font-size: 16px;
    margin-top: 8px;
    margin-right: 1.5rem;
  }

  .menu-list li a {
    padding: 0.5em 24px;

    .badge-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #f14668;
      color: #fff;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .menu-icon {
    padding-right: 8px !important;
  }

  .menu-item {
    &--section {
      padding: 10px 0 !important;
      text-transform: uppercase;
    }
  }
  .app-menu-home-icon {
    display: flex;
    padding-right: 9px !important;
  }
  .app-menu-map-icon {
    display: flex;
    padding-right: 18px !important;
  }
  .app-menu-logout-icon {
    display: flex;
    padding-right: 13px !important;
  }
}
