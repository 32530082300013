.is-align-items-center {
  align-items: center !important;
}

.is-pointer {
  cursor: pointer;
}

.op-modal {
  .form-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px !important;
    margin-bottom: 30px !important;
    .label {
      padding-top: 10px;
    }
    label {
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
      display: flex;
      justify-content: right;
    }
    .input-field {
      select {
        width: 80%;
        box-sizing: border-box;
        height: 45px;
        border-radius: 3px;
        border: 1px solid #e5e9f2;
        font-size: 14px;
        padding: 10px 10px;
        margin-left: 20px;
        outline: none;
        &:focus {
          border-color: #3db3e2;
        }
      }
      .css-14el2xx-placeholder {
        font-size: 14px;
      }
      .css-1s2u09g-control {
        border-color: #e5e9f2 !important;
      }
      .react-datepicker__input-container {
        input {
          font-size: 14px;
          &::placeholder {
            color: #122048 !important;
            opacity: 1;
          }
        }
      }
      .unit-select {
        margin-bottom: 8px;
      }
      .checkbox-inner {
        z-index: 0;
      }
      .checkbox-label {
        color: #122048;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
      }
    }
  }
}

.notification {
  align-items: center;
  padding: 10px 15px;
  font-size: 12px;
  margin-top: 10px;
  button {
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
    margin-left: auto;
  }
}

.po-table {
  max-height: 200px;
  font-size: 10px;
  overflow: auto;
  background-color: #f8f9fa;
  padding: 3px 10px;
  margin-top: 15px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  tr {
    &:first-child {
      th {
        padding: 0px !important;
      }
    }

    th {
      padding: 6px !important;
    }
  }
  th {
    color: #122048;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 700;
  }
  td {
    font-size: 14px;
    .cell-item {
      font-size: 14px;
      width: 100%;
      height: 100%;
      padding: 6px;
    }
  }
}
