.vendor-work-card {
  border-radius: 3px;

  &-tag {
    display: inline-flex;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 1px;
    min-width: 91px;
  }

  &-unit {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 16px;
  }
}
