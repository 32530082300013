.services-selector {
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 3px;
  box-sizing: border-box;
  // height: 460px;
  height: auto;
  margin: 23px auto;
  max-width: 839px;
  padding: 40.5px 70px;
  text-align: center;
  width: 100%;

  .services-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }

  .service-item {
    border: 1px solid #e5e9f2;
    border-radius: 5px;
    height: 126px;
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 30px 0;
    position: relative;
    width: 115px;

    &.active {
      border: 1px solid #8dc85c;
      color: #8dc85c;
    }
    
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }

    &.selected {
      background: transparent;
      border: 2px solid #3db3e2;
      color: #3db3e2;

      .label {
        font-weight: bold;
      }
    }

    [class^='turnable-icon-'] {
      font-size: 28px;
    }

    .check {
      background: #8dc85c;
      border-radius: 50%;
      left: 6px;
      padding: 5px;
      position: absolute;
      top: -15px;
    }

    .label {
      color: inherit;
      font-weight: 400;
      margin-top: 10px;
    }
  }

  .subtitle {
    color: #122048;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.36px;
    line-height: 22px;
  }

  .title {
    color: #122048;
    font-size: 30px;
    font-weight: 300;
    line-height: 41px;

    .highlighted {
      color: #3DB3E2;
      font-weight: bold;
    }
  }
}
